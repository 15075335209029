import { RTKQueryTags } from '../../app/common/Enums';
import { APIResponseModel, AnswerCompositeModel, AnswerModel, QuestionFileModel, QuestionFileUploadModel, QuestionModel, ResponseStreamHandler } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

type QuestionAssignParams = {
  projectId: number,
  sectionId: number | null,
  assignType: number,
  userIds: number[],
  questionIds: number[] | null,
  replaceExisting: boolean
}

type UpdateAnswerParams = {
  projectId: number,
  questionId: number,
  answerId: number,
  answer?: string,
  statusId: number
}

type UpdateStatusParams = {
  projectId: number,
  questionIds: number[],
  statusId: number
}

type RequestChangesParams = {
  projectId: number,
  questionId: number,
  answerId: number,
  comment: string
}

const QuestionApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    addSingleQuestion: builder.mutation<APIResponseModel<QuestionModel | null>, QuestionModel>({
      query: (model) => ({
        url: '/question/add',
        method: "POST",
        body: model
      }),
    }),
    addQuestionFile: builder.mutation<APIResponseModel<QuestionFileUploadModel | null>, QuestionFileUploadModel>({
      query: (questionFileUploadModel) => ({
        url: '/question/addfile',
        method: "POST",
        body: questionFileUploadModel
      }),
    }),
    getQuestionsToReview: builder.query<QuestionModel[], { projectId: number, importId: number }>({
      query: (params) => ({
        url: '/question/unreviewed',
        method: "POST",
        body: params
      }),
      transformResponse: (response: APIResponseModel<QuestionModel[]>) => response.data || [],
      providesTags: (_) => [RTKQueryTags.QuestionReviewList],
      keepUnusedDataFor: 0,
    }),
    getQuestionFiles: builder.query<APIResponseModel<QuestionFileModel[]>, { projectId: number }>({
      query: (params) => ({
        url: `/question/question_files/${params.projectId}`,
        method: "GET"
      }),
      keepUnusedDataFor: 0,
    }),
    saveQuestionsToReview: builder.mutation<APIResponseModel<QuestionFileModel>, QuestionModel[]>({
      query: (questions) => ({
        url: '/question/review',
        method: "POST",
        body: questions
      }),
      transformResponse: (response: APIResponseModel<any>) => {
        if (response.data && response.data.length)
          response.data = response.data[0];
        return response;
      },
    }),
    deleteQuestionsUnreviewed: builder.mutation<APIResponseModel<QuestionFileModel>, { projectId: number, importId: number, questionIds: number[] }>({
      query: (params) => ({
        url: '/question/delete_unreviewed',
        method: "DELETE",
        body: params
      }),
      transformResponse: (response: APIResponseModel<any>) => {
        if (response.data && response.data.length)
          response.data = response.data[0];
        return response;
      }
    }),
    getQuestions: builder.query<QuestionModel[], { projectId: number, sectionId: number | null }>({
      query: (params) => ({
        url: '/question/questions',
        method: "POST",
        body: params
      }),
      transformResponse: (response: APIResponseModel<QuestionModel[]>) => response.data || [],
      providesTags: (_) => [RTKQueryTags.QuestionList],
      keepUnusedDataFor: 0,
    }),
    reorderQuestions: builder.mutation<APIResponseModel<void>, { projectId: number, sectionId: number, questionIds: number[] }>({
      query: (params) => ({
        url: '/question/reorder',
        method: "POST",
        body: params
      })
    }),
    deleteQuestions: builder.mutation<APIResponseModel<void>, { projectId: number, questionIds: number[] }>({
      query: (params) => ({
        url: '/question/delete',
        method: "DELETE",
        body: params
      }),
      transformResponse: (response: APIResponseModel<any>) => {
        if (response.data && response.data.length)
          response.data = response.data[0];
        return response;
      }
    }),
    getAnswers: builder.mutation<APIResponseModel<AnswerCompositeModel>, { projectId: number, sectionId: number | null, questionIds: number[], timestamp: number }>({
      query: (params) => ({
        url: '/question/answer_list',
        method: "POST",
        body: params
      })
    }),
    assignQuestions: builder.mutation<APIResponseModel<void>, QuestionAssignParams>({
      query: (params) => ({
        url: params.assignType === 0 ? '/question/assign_user' : '/question/assign_approver',
        method: "POST",
        body: params
      })
    }),
    updateResponseFormat: builder.mutation<APIResponseModel<QuestionModel>, { question: QuestionModel, regenerateResponse: number[] | null }>({
      query: (params) => ({
        url: '/question/update_response_format',
        method: "POST",
        body: {
          projectId: params.question.projectId,
          questionId: params.question.questionId,
          datasetId: params.question.datasetId,
          responseFormat: params.question.responseFormat,
          regenerateResponse: params.regenerateResponse
        }
      })
    }),
    selectAnswer: builder.mutation<APIResponseModel<AnswerCompositeModel>, { projectId: number, questionId: number, answerId: number, answerNo: number }>({
      query: (params) => ({
        url: '/question/select_answer',
        method: "POST",
        body: params
      })
    }),
    deleteAnswer: builder.mutation<APIResponseModel<void>, { projectId: number, answerId: number, questionId: number }>({
      query: (params) => ({
        url: `/question/delete_answer`,
        method: "DELETE",
        body: params
      })
    }),
    saveAnswer: builder.mutation<APIResponseModel<AnswerModel>, UpdateAnswerParams>({
      query: (params) => ({
        url: '/question/update_answer',
        method: "POST",
        body: params
      })
    }),
    composeAnswer: builder.mutation<APIResponseModel<AnswerCompositeModel>, AnswerModel>({
      query: (params) => ({
        url: '/question/compose_answer',
        method: "POST",
        body: params
      })
    }),
    updateQuestionStatus: builder.mutation<APIResponseModel<{ questionId: number, statusId: number }[]>, UpdateStatusParams>({
      query: (params) => ({
        url: '/question/update_status',
        method: "POST",
        body: params
      })
    }),
    requestChangesAnswer: builder.mutation<APIResponseModel<AnswerModel>, RequestChangesParams>({
      query: (params) => ({
        url: '/question/answer_request_change',
        method: "POST",
        body: params
      })
    }),
    responseGenerator: builder.mutation<void, ResponseStreamHandler>({
      query: (params) => ({
        url: '/question/response_generator',
        method: "POST",
        body: params.data,
        responseHandler: async (response) => {

          if (response.ok && response.body) {
            const reader = response.body.getReader();
            let done, value;
            const decoder = new TextDecoder();

            while (!done) {
              ({ value, done } = await reader.read());

              const decodedChunk = decoder.decode(value, { stream: true });
              params.onChunk(decodedChunk);
            }
          }
        }
      })
    }),
    saveAttachment: builder.mutation<APIResponseModel<any>, FormData>({
      query: (params) => ({
        url: '/question/save_attachment',
        method: "POST",
        formData: true,
        body: params
      })
    }),
  }),
})

export const {
  useLazyGetQuestionsToReviewQuery,
  useGetQuestionsToReviewQuery,
  useAddSingleQuestionMutation,
  useAddQuestionFileMutation,
  useSaveQuestionsToReviewMutation,
  useDeleteQuestionsUnreviewedMutation,
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  useReorderQuestionsMutation,
  useGetAnswersMutation,
  useAssignQuestionsMutation,
  useUpdateResponseFormatMutation,
  useSelectAnswerMutation,
  useDeleteAnswerMutation,
  useSaveAnswerMutation,
  useComposeAnswerMutation,
  useUpdateQuestionStatusMutation,
  useLazyGetQuestionFilesQuery,
  useRequestChangesAnswerMutation,
  useDeleteQuestionsMutation,
  useResponseGeneratorMutation,
  useSaveAttachmentMutation
} = QuestionApi