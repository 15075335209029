import { App, Form, Input, Spin } from "antd";
import _ from "lodash";
import React from "react";
import { useSaveProductScriptMutation } from "../../../redux/rtkquery/PricingApi";
import FullScreenModal from "../../common/components/FullScreenModal";
import { ProductPricingModel } from "../../models";

type ComponentProps = {
    onModalClose: (productPricing?: ProductPricingModel | null) => void,
    productPricing: ProductPricingModel,
    companyId: number
}

const AddEditProductScript: React.FC<ComponentProps> = (props) => {
    const productPricing = _.cloneDeep(props.productPricing);
    const [form] = Form.useForm<ProductPricingModel>();
    const [triggerSaveProductScript, saveProductScriptResult] = useSaveProductScriptMutation();
    const { notification } = App.useApp();

    const onFinishFailed = () => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = async (values: ProductPricingModel) => {

        const _productPricing = {
            ...productPricing,
            productScript: _.trim(values.productScript)
        }

        let response = await triggerSaveProductScript({
            companyId: props.companyId,
            id: _productPricing.id,
            productScript: _productPricing.productScript
        });

        if (response && 'data' in response && response.data.success) {
            props.onModalClose(_productPricing);
        }
    }

    return (
        <FullScreenModal title={
            <h3>{productPricing.id ? 'Edit' : 'Add'} Script for {productPricing.pricingName}</h3>
        }
            centered
            open={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            width={800}
            destroyOnClose={true}
            onCancel={() => props.onModalClose()}
            cancelButtonProps={{ disabled: saveProductScriptResult.isLoading }}
            okText="Save"
            onOk={form.submit}
            confirmLoading={saveProductScriptResult.isLoading}
        >
            <Spin spinning={saveProductScriptResult.isLoading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="AddEditProductScript"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={productPricing}
                >
                    <div className="text-gray-700 fs-6 fw-normal text-wrap">
                        <p className="mb-1">Provide Price script for the product.</p>
                    </div>

                    <Form.Item name="productScript" label="Product Script"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Input.TextArea autoSize rows={7}
                            style={{ minHeight: '200px' }}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </FullScreenModal>
    )
}

export { AddEditProductScript };
