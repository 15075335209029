import { App, Button, Form, Input, Select, Space, Spin, Switch, message } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useAppSelector } from "../../../redux/hooks";
import { selectMasterDataByType } from "../../../redux/MasterDataSlice";
import { postDataAsyncOnAPI } from "../../../redux/requests";
import { IsSuperAdmin, useStateWithCallback } from "../../common/CommonFunctions";
import { Roles } from "../../common/Enums";
import { SystemSettingModel, UserModel } from "../../models";
import { useAuth } from "../auth";

type ComponentProps = {
    onUserSave: (user?: UserModel) => void,
    user: UserModel
}

const AddEditUser: React.FC<ComponentProps> = (props) => {
    const { user_metadata } = useAuth();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { modal, notification } = App.useApp();
    const [selectedUser, setSelectedUser] = useStateWithCallback({ ..._.cloneDeep(props.user) });
    const [roles, setRoles] = useState<SystemSettingModel[]>([]);
    const allRoles = useAppSelector(state => selectMasterDataByType(state, "Roles"));

    const onFinishFailed = (errorInfo: any) => {
        message.error("Please fix highlighted errors");
    }

    const onSaveInvite = () => {
        selectedUser.invite = true;
        setSelectedUser(selectedUser, form.submit);
    }

    const onSave = () => {
        selectedUser.invite = false;
        setSelectedUser(selectedUser, form.submit);
    }

    const onFinish = async (values: UserModel) => {

        const _user = {
            ...selectedUser,
            ...values,
        }

        if (!values.is2fa && props.user.is2fa) {
            modal.confirm({
                okText: "Yes",
                cancelText: "No",
                title: "Confirm Remove 2FA",
                content: <><p>By continuing, you are about to disable associated two-factor authentications from this account.</p>
                    <p>Are you sure you want to proceed?</p>
                </>,
                onOk: () => {
                    saveChanges(_user);
                }
            });
            return;
        }

        saveChanges(_user);
    }

    const saveChanges = async (user: UserModel) => {
        setLoading(true);

        let response = await postDataAsyncOnAPI<UserModel>('user/save', user);
        setLoading(false);

        if (response.success) {
            props.onUserSave(response.data || undefined);
            return;
        }

        notification.error({
            message: "Error!",
            description: response.message || "Unable to save changes",
            placement: "topRight"
        });
    }

    const onCancel = () => {
        props.onUserSave();
    }

    useEffect(() => {
        let filteredRoles = _.cloneDeep(allRoles),
            disabledRoles = [Roles.SuperAdmin, Roles.PricingAdmin, Roles.PricingMember],
            isSuperAdmin = IsSuperAdmin(user_metadata?.roles);

        // Disable disabled roles selection by default
        filteredRoles.forEach(x => x.disabled = disabledRoles.indexOf(x.value) >= 0);

        // Enable all roles for Super Admin
        if (isSuperAdmin) {
            let superAdminRole: any = null;

            filteredRoles.forEach(x => {
                if (x.value === Roles.SuperAdmin) {
                    superAdminRole = x;
                    return;
                }
                x.disabled = false;
            });

            // Enable Super Admin role option for Current Super Admin User and within same company
            if (superAdminRole && user_metadata?.companyId === selectedUser.companyId) {
                // Keep disabled for self
                if(user_metadata?.userId !== selectedUser.userId)
                    superAdminRole.disabled = false;
            }
            else {
                // Remove Super Admin option
                filteredRoles = filteredRoles.filter(x => x.value !== Roles.SuperAdmin);
            }
        }
        else {
            // Remove Super Admin option
            filteredRoles = filteredRoles.filter(x => x.value !== Roles.SuperAdmin);

            // Remove disabled roles or keep if already assigned
            filteredRoles = filteredRoles.filter(x => disabledRoles.indexOf(x.value) < 0 || selectedUser.roles.indexOf(x.value) >= 0);
        }

        setRoles(filteredRoles);

    }, []);

    return (
        <Spin spinning={loading}>
            <Form
                layout="vertical"
                form={form}
                name="AddEditUser"
                initialValues={selectedUser}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row>
                    <Col md={6}>
                        <Form.Item name="firstName" label="First Name"
                            rules={[{ required: true, message: "'${label}' is required" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={6}>
                        <Form.Item name="lastName" label="Last Name"
                            rules={[{ required: true, message: "'${label}' is required" }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="email" label="Email"
                    rules={[
                        { required: true, message: "'${label}' is required" },
                        { type: 'email' },
                        { type: 'string', min: 5, max: 200 }
                    ]}>
                    <Input disabled={selectedUser.userId > 0} />
                </Form.Item>

                <Row>
                    <Col md={6}>
                        <Form.Item name="phoneNo" label="Phone">
                            <PhoneInput international
                                defaultCountry="US"
                                placeholder="Enter phone number"
                                onChange={() => { }}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={6}>
                        <Form.Item name="roles" label="Roles"
                            rules={[{ required: true, message: "'${label}' is required" }]}>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Select Role"
                                fieldNames={{ label: "name" }}
                                options={roles}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Item name="isActive" label="Account Status" valuePropName="checked">
                            <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={false} />
                        </Form.Item>
                    </Col>
                    <Col md={6}>
                        <Form.Item name="is2fa" label="2FA Required" valuePropName="checked">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="text-end mb-0">
                    <Space>
                        <Button size="large" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" size="large" onClick={onSaveInvite}>
                            Save and Invite
                        </Button>
                        <Button type="primary" size="large" onClick={onSave}>
                            Save
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    )
};

export { AddEditUser };

