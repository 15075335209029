import { Card, Spin } from "antd";
import { useEffect, useState } from "react";
import { useOpenQuotesStatsMutation, useQuotesForApprovalStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { DashboardOpenQuoteStats } from "../../models";
import { localeCurrency, localeNumber } from "../../common/CommonFunctions";

const OpenQuotesStats: React.FC = () => {
  const [quotesStats, setQuotesStats] = useState<DashboardOpenQuoteStats | null>(null);
  const [approvalQuotesStats, setApprovalQuotesStats] = useState<DashboardOpenQuoteStats | null>(null);
  const [triggerQuotesStats, quotesStatsResult] = useOpenQuotesStatsMutation();
  const [triggerApprovalQuotesStats, approvalQuotesStatsResult] = useQuotesForApprovalStatsMutation();

  useEffect(() => {
    triggerQuotesStats().then(response => {
      if ('data' in response && response.data.success)
        setQuotesStats(response.data.data);
    });
    triggerApprovalQuotesStats().then(response => {
      if ('data' in response && response.data.success)
        setApprovalQuotesStats(response.data.data);
    });
  }, []);

  return (
    <>
      <Card className="h-50">
        <Spin spinning={quotesStatsResult.isLoading}>
          <div className="d-flex justify-content-center d-grid mt-3">
            <div className="px-5 text-center flex-fill">
              <div className="fs-2hx fw-bold lh-1 ls-n2 mb-2">{localeNumber(quotesStats?.requestCount ?? 0)}</div>
              <div className="fs-6 fw-semibold text-gray-500">Active Quotes</div>
            </div>

            <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
              <div className="fs-2 text-gray-700 lh-1 ls-n2 mb-2">{localeCurrency(quotesStats?.totalPrice ?? 0)}</div>
              <div className="fs-6 fw-semibold text-gray-500">Total Price</div>
            </div>

            <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
              <div className="fs-2 text-gray-700 lh-1 ls-n2 mb-2">{localeNumber(quotesStats?.totalQuantity ?? 0)}</div>
              <div className="fs-6 fw-semibold text-gray-500">Total Quantities</div>
            </div>
          </div>
        </Spin>
      </Card>

      <Card className="h-50">
        <Spin spinning={approvalQuotesStatsResult.isLoading}>
          <div className="d-flex justify-content-center d-grid mt-3">
            <div className="px-5 text-center flex-fill">
              <div className="fs-2hx fw-bold lh-1 ls-n2 mb-2">{localeNumber(approvalQuotesStats?.requestCount ?? 0)}</div>
              <div className="fs-6 fw-semibold text-gray-500">Quotes for Approval</div>
            </div>

            <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
              <div className="fs-2 text-gray-700 lh-1 ls-n2 mb-2">{localeCurrency(approvalQuotesStats?.totalPrice ?? 0)}</div>
              <div className="fs-6 fw-semibold text-gray-500">Total Price</div>
            </div>

            <div className="border-start-dashed border-start border-gray-300 px-5 text-center flex-fill">
              <div className="fs-2 text-gray-700 lh-1 ls-n2 mb-2">{localeNumber(approvalQuotesStats?.totalQuantity ?? 0)}</div>
              <div className="fs-6 fw-semibold text-gray-500">Total Quantities</div>
            </div>
          </div>
        </Spin>
      </Card>
    </>


  )
}

export { OpenQuotesStats };

