import { useIntl } from 'react-intl'
import { IsCollaborator, IsCompanyAdmin, IsPricingAdmin, IsPricingMember, IsProjectOwner, IsSuperAdmin } from '../../../../../app/common/CommonFunctions'
import { useAuth } from '../../../../../app/modules/auth'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
    const intl = useIntl()
    const { user_metadata } = useAuth();
    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                icon='/media/icons/duotune/art/art002.svg'
            />

            {
                (IsCompanyAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles) || IsCollaborator(user_metadata?.roles)) &&
                <SidebarMenuItemWithSub
                    to='/projects'
                    title='Projects'
                    icon='/media/icons/duotune/general/gen005.svg'
                >
                    {
                        (IsCompanyAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles)) &&
                        <SidebarMenuItem to='/projects/add-project' title='Add Project' hasBullet={true} />
                    }
                    <SidebarMenuItem to='/projects/list' title='Projects' hasBullet={true} />
                </SidebarMenuItemWithSub>
            }

            {
                (IsPricingAdmin(user_metadata?.roles) || IsPricingMember(user_metadata?.roles)) &&
                <SidebarMenuItemWithSub
                    to='/quotes'
                    title='Quotes'
                    icon='/media/icons/duotune/finance/fin010.svg'
                >
                    {
                        <SidebarMenuItem to='/quotes/new-quote' title='New Quote' hasBullet={true} />
                    }
                    <SidebarMenuItem to='/quotes/list' title='Quotes' hasBullet={true} />
                </SidebarMenuItemWithSub>
            }

            {
                (IsPricingAdmin(user_metadata?.roles) || IsCompanyAdmin(user_metadata?.roles)) &&
                <SidebarMenuItemWithSub
                    to='/settings'
                    title='Settings'
                    icon='/media/icons/duotune/coding/cod001.svg'
                >
                    {
                        IsCompanyAdmin(user_metadata?.roles) &&
                        <>
                            <SidebarMenuItem to='/settings/setup/about' title='Company Setup' hasBullet={true} />
                            <SidebarMenuItem to='/settings/users' title='Users' hasBullet={true} />
                            <SidebarMenuItem to='/settings/library/list' title='Library' hasBullet={true} />
                            <SidebarMenuItem to='/settings/library/edit-requests' title='Library Edit Requests' hasBullet={true} />
                        </>
                    }
                    {
                        IsPricingAdmin(user_metadata?.roles) &&
                        <SidebarMenuItem to={`/settings/price-book/${user_metadata?.companyId}`} title='Price Books' hasBullet={true} />
                    }
                </SidebarMenuItemWithSub>
            }

            {
                IsSuperAdmin(user_metadata?.roles) &&
                <SidebarMenuItemWithSub
                    to='/admin'
                    title='Admin'
                    icon='/media/icons/duotune/general/gen049.svg'
                >
                    <SidebarMenuItem to='/admin/companies' title='Company List' hasBullet={true} />
                </SidebarMenuItemWithSub>
            }



            {/* 
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
                </div>
            </div>
            <SidebarMenuItemWithSub
                to='/crafted/pages'
                title='Pages'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen022.svg'
            >
                <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
                    <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
                    <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
                    <SidebarMenuItem
                        to='/crafted/pages/profile/campaigns'
                        title='Campaigns'
                        hasBullet={true}
                    />
                    <SidebarMenuItem
                        to='/crafted/pages/profile/documents'
                        title='Documents'
                        hasBullet={true}
                    />
                    <SidebarMenuItem
                        to='/crafted/pages/profile/connections'
                        title='Connections'
                        hasBullet={true}
                    />
                </SidebarMenuItemWithSub>

                <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
                    <SidebarMenuItem
                        to='/crafted/pages/wizards/horizontal'
                        title='Horizontal'
                        hasBullet={true}
                    />
                    <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
                </SidebarMenuItemWithSub>
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
                to='/crafted/accounts'
                title='Accounts'
                icon='/media/icons/duotune/communication/com006.svg'
                fontIcon='bi-person'
            >
                <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
                <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
                to='/error'
                title='Errors'
                fontIcon='bi-sticky'
                icon='/media/icons/duotune/general/gen040.svg'
            >
                <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
                <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub
                to='/crafted/widgets'
                title='Widgets'
                icon='/media/icons/duotune/general/gen025.svg'
                fontIcon='bi-layers'
            >
                <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
                <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
                <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
                <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
                <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
                <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
                </div>
            </div>
            <SidebarMenuItemWithSub
                to='/apps/chat'
                title='Chat'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/communication/com012.svg'
            >
                <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
                <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
                <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItem
                to='/apps/user-management/users'
                icon='/media/icons/duotune/general/gen051.svg'
                title='User management'
                fontIcon='bi-layers'
            />
            <div className='menu-item'>
                <a
                    target='_blank'
                    className='menu-link'
                    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
                >
                    <span className='menu-icon'>
                        <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
                    </span>
                    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
                </a>
            </div> */}
        </>
    )
}

export { SidebarMenuMain }
