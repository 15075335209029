import { Form, Spin } from "antd";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { useFinalPricingGeneratorMutation, useSetRequestStatusMutation } from "../../../redux/rtkquery/PricingApi";
import FullScreenModal from "../../common/components/FullScreenModal";
import HtmlEditor from "../../common/components/HtmlEditor";
import { PricingRequestStatus } from "../../common/Enums";
import { PricingRequestModel, PricingResponseModel } from "../../models";

export const FinalizePricing: React.FC<{
    response: PricingResponseModel,
    onClose: (model?: PricingRequestModel | null) => void,
    isFinalSubmit: boolean
}> = (props) => {

    const [form] = Form.useForm<PricingResponseModel>();
    const editorRef = useRef<any>(null);
    const [triggerPricingGenerator, pricingGeneratorResult] = useFinalPricingGeneratorMutation();
    const [triggerSetRequestStatus, setRequestStatusResult] = useSetRequestStatusMutation();

    const onFinish = async (values: PricingResponseModel) => {
        if (!_.trim(editorRef.current.getContent({ format: "text" }))) {
            form.setFieldValue("response", null);
            form.validateFields();
            return;
        }

        let response = await triggerSetRequestStatus({
            pricingRequestId: props.response.pricingRequestId,
            statusId: props.isFinalSubmit ? PricingRequestStatus.Final : PricingRequestStatus.Submitted,
            finalizedResponse: values.response,
            comment: null
        });

        if ('data' in response && response.data.success && response.data.data) {
            props.onClose(response.data.data);
        }
    }

    useEffect(() => {
        // Generate Final Response
        (async () => {
            let response = await triggerPricingGenerator({
                pricingRequestId: props.response.pricingRequestId,
                pricingInstructionId: props.response.pricingInstructionId
            });

            if ('data' in response && response.data.success && response.data.data) {
                form.setFieldValue("response", response.data.data);
            }
        })();
    }, []);

    return (
        <FullScreenModal title="Final Quote"
            open={true}
            maskClosable={false}
            keyboard={false}
            closable={false}
            width={800}
            destroyOnClose={true}
            onCancel={(e) => props.onClose()}
            cancelButtonProps={{ disabled: setRequestStatusResult.isLoading }}
            onOk={form.submit}
            okText="Save Changes"
            okButtonProps={{ loading: setRequestStatusResult.isLoading, disabled: pricingGeneratorResult.isLoading }}
        >
            <Spin spinning={pricingGeneratorResult.isLoading || setRequestStatusResult.isLoading}>

                <div className="text-gray-600 mb-8 fs-4">
                    Finalize the quote for this request.
                </div>

                <Form
                    layout="vertical"
                    form={form}
                    name="EditResponse"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={props}
                >
                    <Form.Item name="response" label=''
                        rules={[{ required: true, message: "'Final Quote' is required" }]}>
                        <HtmlEditor setEditorRef={(editor: any) => editorRef.current = editor} />
                    </Form.Item>
                </Form>
            </Spin>
        </FullScreenModal>
    )
}