import { GridOptions, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Card } from "antd";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { useRecentProjectsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { ProjectListItemModel } from "../../models";

const RecentProjects: React.FC = () => {
  const navigate = useNavigate();
  const [triggerRecentProjects] = useRecentProjectsMutation();

  const recentProjectsGridOptions: GridOptions<ProjectListItemModel> = {
    defaultColDef: {
      sortable: true,
      filter: false,
      resizable: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      autoHeight: true,
      suppressMovable: true,
      suppressHeaderMenuButton: true,
      suppressAutoSize: true,
      onCellClicked: (event) => {
        navigate(`/projects/${event.data?.projectId}`)
      },
    },
    columnDefs:
      [
        {
          field: 'projectName',
          headerName: 'Project',
          flex: 1,
          cellRenderer: (params: ICellRendererParams<ProjectListItemModel>) =>
            <div className="lh-1 py-2">
              <div className="fw-bold pb-2">
                {params.value}
              </div>
              <div className="fs-8 text-muted">Due on {dayjs(params.data?.dueDate).format("DD MMM YY")}</div>
            </div>
        },
        {
          field: 'customerName',
          headerName: 'Customer',
          width: 150,
          cellClass: "d-flex-v-center"
        }
      ],
    rowData: null,
    rowHeight: 25,
    headerHeight: 25,
    onGridReady: (params) => {
      triggerRecentProjects({ limit: 10 })
        .then((response) => {
          let rowData = 'data' in response ? response.data.data ?? [] : [];
          params.api.updateGridOptions({ rowData: rowData });
        })
        .finally(() => {
          params.api.updateGridOptions({ loading: false });
        })
    }
  }

  return (
    <Card size="small" bordered={true} title={<span className="fs-4">Recent Projects</span>}
    >
      <div className="ag-theme-alpine ag-theme-alpine-custom w-100" style={{ height: '200px' }}>
        <AgGridReact gridOptions={recentProjectsGridOptions} />
      </div>
    </Card>
  )
}

export { RecentProjects };

