import { Button, Form, Input, Select, Space, Spin, Switch, message } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from "../../../redux/hooks";
import { selectMasterDataByType } from "../../../redux/MasterDataSlice";
import { postDataAsyncOnAPI } from "../../../redux/requests";
import { AntdSystemSettingsSelectSearchFn } from "../../common/CommonFunctions";
import { CompanyProfileModel } from "../../models";

type ComponentProps = {
    onCompanySave: (company?: CompanyProfileModel | null) => void,
    company: CompanyProfileModel
}

const AddEditCompany: React.FC<ComponentProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const selectedCompany = _.cloneDeep(props.company);
    const countries = useAppSelector(state => selectMasterDataByType(state, "Countries"));

    const onFinishFailed = (errorInfo: any) => {
        message.error("Please fix highlighted errors");
    }

    const onFinish = async (values: CompanyProfileModel) => {
        setLoading(true);
        const _company = {
            ...selectedCompany,
            ...values,
        }

        let response = await postDataAsyncOnAPI<CompanyProfileModel>('company/save', _company);
        setLoading(false);

        if (response.success) {
            props.onCompanySave(response.data);
            return;
        }

        message.error(response.message || "Unable to save changes");
    }

    const onCancel = () => {
        props.onCompanySave();
    }

    return (
        <Spin spinning={loading}>
            <Form
                layout="vertical"
                form={form}
                name="AddEditCompany"
                initialValues={selectedCompany}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item name="name" label="Name"
                    rules={[{ required: true, message: "'${label}' is required" }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="description" label="Description"
                    rules={[{ type: 'string', max: 450 }]}>
                    <Input />
                </Form.Item>

                <Row>
                    <Col lg={6}>
                        <Form.Item name="address1" label="Address 1"
                            rules={[{ type: 'string', max: 450 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="address2" label="Address 2"
                            rules={[{ type: 'string', max: 450 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="city" label="City"
                            rules={[{ type: 'string', max: 90 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="county" label="State"
                            rules={[{ type: 'string', max: 90 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="countryId" label="Country">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Select Country"
                                fieldNames={{ label: "name" }}
                                optionFilterProp="children"
                                options={countries}
                                filterOption={AntdSystemSettingsSelectSearchFn}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="zipcode" label="Zip Code"
                            rules={[{ type: 'string', max: 45 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="phoneNo" label="Phone Number"
                            rules={[{ type: 'string', max: 45 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Item name="email" label="Contact Email"
                            rules={[
                                { type: 'email' },
                                { type: 'string', min: 5, max: 200 }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={3}>
                        <Form.Item name="isActive" label="Account Status" valuePropName="checked">
                            <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={false} />
                        </Form.Item>
                    </Col>
                    <Col md={3}>
                        <Form.Item name="is2fa" label="2FA Required" valuePropName="checked">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className="text-end mb-0">
                    <Space>
                        <Button size="large" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" size="large" htmlType="submit">
                            Save
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export { AddEditCompany };

