import { App, Button, Drawer, Space, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';
import { getDataFromAPI } from '../../../redux/requests';
import { CompanyProfileModel } from '../../models';
import { AddEditCompany } from './AddEditCompany';

const Companies: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState<CompanyProfileModel | null>(null);
    const [companyList, setCompanyList] = useState<CompanyProfileModel[]>([]);
    const { notification } = App.useApp();

    useEffect(() => {
        getDataFromAPI<CompanyProfileModel[]>('company/list')
            .then(response => {
                setCompanyList(response.data || []);
                setLoading(false);
            })
            .catch(error => {
                notification.error({
                    message: "Error",
                    description: "Unable to fetch company list",
                    placement: "topRight"
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);


    const columns: ColumnsType<CompanyProfileModel> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'companyId',
            sorter: true
        },
        {
            title: 'DBA',
            dataIndex: 'dba',
            key: 'dba',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            align: 'center',
            sorter: true,
            render: (_, record) => record.isActive ? "Active" : "Inactive"
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <a href='#' onClick={() => editUser(record)}
                        title='Edit'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-4' />
                    </a>

                    <Link to={`/admin/users/${record.companyId}`}
                        title='Users'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <i className="bi bi-people-fill svg-icon-4" />
                    </Link>

                    <Link to={`/settings/price-book/${record.companyId}`}
                        title='Price Books'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <i className="bi bi-cash-stack svg-icon-4"></i>
                    </Link>
                </Space>
            ),
        },
    ];

    const addCompany = () => {
        setSelectedCompany({
            companyId: 0,
            name: '',
            dbaName: '',
            webUrl: '',
            shortDescription: '',
            description: '',
            phoneNo: '',
            address1: '',
            address2: '',
            city: '',
            county: '',
            country: '',
            countryId: null,
            learnFromWebsite: false,
            isActive: true,
            is2fa: false
        })
    }

    const editUser = (record: CompanyProfileModel) => {
        const _company = _.cloneDeep(record);
        setSelectedCompany(_company);
    }

    const onCompanySave = (updatedCompany?: CompanyProfileModel | null) => {
        if (updatedCompany) {
            if (selectedCompany?.companyId) {
                let indexId = companyList.findIndex(x => x.companyId === updatedCompany.companyId);
                companyList[indexId] = updatedCompany;
            }
            else {
                companyList.push(updatedCompany);
            }

            setCompanyList(companyList.map(x => x));
        }
        setSelectedCompany(null);
    }

    return (<>
        <Spin spinning={loading}>
            <div className="text-end">
                <Button type="primary" className="mb-2" onClick={addCompany}>
                    Add New
                </Button>
            </div>
            <Table rowKey={"companyId"} columns={columns} dataSource={companyList} size="small" pagination={{ hideOnSinglePage: true }} />
        </Spin>
        {
            selectedCompany &&
            <Drawer title={`${selectedCompany.companyId ? 'Edit' : 'Add'} Company`}
                width={700}
                open={true}
                maskClosable={false}
                keyboard={false}
                destroyOnClose={true}
                onClose={() => onCompanySave()}
            >
                <AddEditCompany company={selectedCompany} onCompanySave={onCompanySave} />
            </Drawer>
        }
    </>
    )
}

export { Companies };

