import { FilterOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { GridApi, GridOptions, ICellRendererParams, IGetRowsParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Card, DatePicker, Input, Radio, Select, SelectProps, Space } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useGetCustomerSelectListQuery } from '../../../redux/rtkquery/CustomerApi';
import { useGetPricingRequestListMutation } from '../../../redux/rtkquery/PricingApi';
import { useGetUserSelectListQuery } from '../../../redux/rtkquery/UserApi';
import { AntdOptionSearchFn, getFormattedDate, IsCompanyAdmin, IsPricingAdmin } from '../../common/CommonFunctions';
import useWindowResize from '../../common/components/useWindowResize';
import { AntDRangePresets, CONST_DATE_FORMAT } from '../../common/Constants';
import { PricingRequestStatus, PricingRequestStatusDetails, Roles } from '../../common/Enums';
import { ActiveUserModel, PricingRequestModel } from '../../models';
import { useAuth } from '../auth';
import AntSelect from '../../common/components/AntdSelect';

type Filters = {
    requestName: string | null,
    dateRange: any | null,
    fromDate: string | null,
    toDate: string | null,
    statusId: number[],
    customerId: number[],
    team: number[],
    approver: number[],
    totalRecords: number,
    pageSize: number,
    approvalStatusId: number[],
    listingFilterType: ListingFilterType
}

enum ListingFilterType {
    Quotes = 1,
    Approvals = 2
}

enum ApprovalStatusType {
    PendingApprovals = 1,
    Approved = 2
}

const PricingRequests: React.FC = () => {
    const navigate = useNavigate();
    const { user_metadata } = useAuth();
    const [triggerGetPricingRequestList, getRequestsResult] = useGetPricingRequestListMutation();
    const defaultFilters = {
        requestName: null,
        customerId: [],
        dateRange: null,
        fromDate: null,
        toDate: null,
        statusId: [],
        team: [],
        approver: [],
        totalRecords: 0,
        pageSize: 15,
        approvalStatusId: [],
        listingFilterType: ListingFilterType.Quotes
    };
    const [filters, setFilters] = useState<Filters>(cloneDeep(defaultFilters));
    const [gridApi, setGridApi] = useState<GridApi<PricingRequestModel> | null>(null);
    const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);
    const gridFilters = useRef<Filters>(cloneDeep(defaultFilters));
    const gridRef = useRef<AgGridReact<PricingRequestModel>>(null);
    const allCustomerListState = useGetCustomerSelectListQuery();
    const allUserListState = useGetUserSelectListQuery();
    const allStatusList = _.transform(PricingRequestStatusDetails, (r: SelectProps['options'], v) => r && r.push({
        label: v.name,
        value: v.value
    }), []);
    const IsAdmin = IsCompanyAdmin(user_metadata?.roles) || IsPricingAdmin(user_metadata?.roles);

    // const onDeleteClick = (params: CellClickedEvent<PricingInstructionListItemModel, any>) => {

    //     modal.confirm({
    //         title: "Confirm deletion",
    //         content: <>
    //             <p>Are you sure you would like to delete price book "{params.data?.instructionName}"?</p>
    //             <p className='text-danger fw-bold'>This action cannot be undone.</p>
    //         </>,
    //         okText: "Delete",
    //         okButtonProps: { danger: true },
    //         cancelText: "No",
    //         onOk: () => onDeletePricingInstruction(params)
    //     });
    // }

    // const onDeletePricingInstruction = async (params: any) => {
    //     let response = await triggerDeletePricingInstruction({ id: params.data.id });

    //     if ('data' in response && response.data.success) {
    //         params.api.applyTransaction({ remove: [params.data] });
    //     }
    // }

    const onRequestNameChange = (e: any) => {
        setFilters(prev => ({
            ...prev,
            requestName: e.target.value
        }))
    }

    const onDateRangeChange = (dateRange: any) => {
        filters.toDate = null;
        filters.fromDate = null;

        if (dateRange) {
            filters.fromDate = dateRange[0].format("MM/DD/YYYY");
            filters.toDate = dateRange[1].format("MM/DD/YYYY");
        }

        setFilters(prev => ({
            ...prev,
            dateRange,
            fromDate: filters.fromDate,
            toDate: filters.toDate
        }))
    }

    const onCustomerChange = (value: number[]) => {
        setFilters(prev => ({
            ...prev,
            customerId: value
        }))
    }

    const onTeamChange = (value: number[]) => {
        setFilters(prev => ({
            ...prev,
            team: value
        }))
    }

    const onApproverChange = (value: number[]) => {
        setFilters(prev => ({
            ...prev,
            approver: value.length ? value : [user_metadata?.userId ?? 0]
        }))
    }

    const onStatusChange = (value: number[]) => {
        setFilters(prev => ({
            ...prev,
            statusId: value
        }))
    }

    const onApprovalStatusChange = (value: number[]) => {
        setFilters(prev => ({
            ...prev,
            approvalStatusId: value.length ? value : [ApprovalStatusType.PendingApprovals]
        }))
    }

    const onListingFilterTypeChange = ({ target: { value } }: any) => {

        let isApprovalsType = value === ListingFilterType.Approvals;

        let _filters = {
            ...filters,
            listingFilterType: value,
            approvalStatusId: isApprovalsType ? (filters.approvalStatusId?.length ? filters.approvalStatusId : [ApprovalStatusType.PendingApprovals]) : filters.approvalStatusId,
            team: [],
            approver: isApprovalsType ? [user_metadata?.userId ?? 0] : []
        };

        setFilters(_filters);
        onApplyFilters(null, _filters);
    }

    const onApplyFilters = (e: any, newFilters: Filters | null = null) => {
        e?.preventDefault();
        newFilters ??= filters;
        let _filters = gridFilters.current;
        _filters.requestName = newFilters.requestName;
        _filters.customerId = newFilters.customerId;
        _filters.fromDate = newFilters.fromDate;
        _filters.toDate = newFilters.toDate;
        _filters.statusId = newFilters.statusId;
        _filters.team = newFilters.team;
        _filters.approver = newFilters.approver;
        _filters.listingFilterType = newFilters.listingFilterType;
        _filters.totalRecords = 0;

        // Overwrite the Status ID case when Approvals 
        if (newFilters.listingFilterType === ListingFilterType.Approvals) {
            _filters.statusId = [];

            if (newFilters.approvalStatusId?.some(x => x === ApprovalStatusType.PendingApprovals))
                _filters.statusId.push(PricingRequestStatus.Submitted, PricingRequestStatus.RequestChanges)

            if (newFilters.approvalStatusId?.some(x => x === ApprovalStatusType.Approved))
                _filters.statusId.push(PricingRequestStatus.Final)
        }

        gridApi?.purgeInfiniteCache();
    }

    const onResetFilters = (e: any) => {
        let newFilters = cloneDeep(defaultFilters);
        setFilters(newFilters);
        onApplyFilters(null, newFilters);
    }

    const toggleFilters = (e: any) => {
        setShowMoreFilters(!showMoreFilters);
    }

    const requestStatus = useCallback((statusId?: PricingRequestStatus) => {

        if (gridFilters.current.listingFilterType === ListingFilterType.Quotes)
            return statusId ? PricingRequestStatusDetails[statusId]?.name ?? "--" : "--"
        else if (statusId === PricingRequestStatus.Final)
            return "Approved"
        else if (statusId === PricingRequestStatus.Submitted)
            return "Pending Approval"
        else if (statusId === PricingRequestStatus.RequestChanges)
            return "Pending Approval - Changes Requested"
        return ""
    }, [gridFilters.current.listingFilterType])

    const gridOptions: GridOptions<PricingRequestModel> = {
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            autoHeight: true,
            suppressMovable: true,
            suppressAutoSize: true,
            sortingOrder: ['asc', 'desc'],
            suppressHeaderFilterButton: true,
            onCellClicked: (params) => {
                navigate(`/quotes/${params.data?.pricingRequestId}`)
            }
        },
        columnDefs:
            [
                {
                    field: 'requestName',
                    headerName: 'Name',
                    flex: 1,
                    //wrapText: true,
                    cellRenderer: (params: ICellRendererParams<PricingRequestModel>) =>
                        <Link to={`/quotes/${params.data?.pricingRequestId}`}>
                            {params.data?.requestName}
                        </Link>
                },
                {
                    field: 'statusId',
                    headerName: 'Status',
                    width: 105,
                    valueFormatter: (params) => requestStatus(params.data?.statusId)
                },
                {
                    field: 'createdBy',
                    headerName: 'Owner',
                    width: 140,
                },
                {
                    field: 'createdDate',
                    headerName: 'Created On',
                    width: 160,
                    valueFormatter: (params) => getFormattedDate(params.value, CONST_DATE_FORMAT)
                },
                {
                    field: 'modifiedDate',
                    headerName: 'Updated On',
                    width: 160,
                    valueFormatter: (params) => getFormattedDate(params.value, CONST_DATE_FORMAT)
                },
                {
                    field: 'customerName',
                    width: 125,
                    headerName: 'Customer'
                },
                {
                    field: 'teamName',
                    headerName: 'Team',
                    width: 140,
                    sortable: false
                },
                {
                    field: 'approverName',
                    headerName: 'Approver',
                    width: 125,
                    sortable: false
                }
            ],
        context: gridFilters,
        rowData: null,
        domLayout: 'autoHeight',
        pagination: true,
        paginationPageSize: 15,
        paginationPageSizeSelector: [15, 30, 50, 100],
        cacheBlockSize: 15,
        infiniteInitialRowCount: 0,
        rowModelType: 'infinite',
        onGridReady: (params) => {
            params.api.applyColumnState({
                state: [{ colId: 'modifiedDate', sort: "desc" }]
            })
            params.api.sizeColumnsToFit();
            setGridApi(params.api);
        },
        onFirstDataRendered: () => {
            gridApi?.sizeColumnsToFit();
        }
    }

    const ShowApproverFilter = useMemo(() => {
        return IsAdmin && filters.listingFilterType === ListingFilterType.Approvals
    }, [filters.listingFilterType]);

    useWindowResize(() => gridRef.current?.api?.sizeColumnsToFit());

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params: IGetRowsParams) => {

                    let pageSize = gridApi.paginationGetPageSize();
                    const page = params.startRow > 0 ? Math.floor(params.endRow / pageSize) : 1,
                        sortModel = params.sortModel[0],
                        _filters = gridFilters.current;

                    triggerGetPricingRequestList({
                        title: _filters.requestName,
                        customerId: _filters.customerId,
                        fromDate: _filters.fromDate,
                        toDate: _filters.toDate,
                        statusId: _filters.statusId,
                        team: _filters.team,
                        pageSize: pageSize,
                        totalRecords: _filters.totalRecords,
                        orderBy: sortModel.colId,
                        orderType: sortModel.sort,
                        offset: params.startRow,
                        currentPage: page,
                        approver: _filters.approver
                    })
                        .then(response => {
                            if ('data' in response) {
                                let data = response.data.data,
                                    totalRecords = data?.totalRecords ?? 0;

                                gridFilters.current.totalRecords = totalRecords;
                                params.successCallback(data?.pricingRequestList || [], totalRecords);
                            }
                            else
                                params.successCallback([], 0);
                        }).catch(err => {
                            params.successCallback([], 0);
                        })
                }
            }
            gridApi.updateGridOptions({ datasource: dataSource })
        }
    }, [gridApi]);

    return (
        <Card title={<h2 className='m-0'>Quotes</h2>}
            bodyStyle={{ minHeight: 'calc(100vh - 200px)' }}
            extra={
                <Space>
                    <Radio.Group
                        options={[
                            { label: 'Quotes', value: ListingFilterType.Quotes },
                            { label: 'Approvals', value: ListingFilterType.Approvals }
                        ]}
                        value={filters.listingFilterType}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={onListingFilterTypeChange}
                    />

                    <Link to='/quotes/new-quote'>
                        <Button className='btn-primary-outline'
                            icon={<PlusCircleOutlined rev={0} />}>
                            Add New
                        </Button>
                    </Link>
                </Space>

            }
        >
            <form autoComplete='off'
                onSubmit={onApplyFilters}
                onReset={onResetFilters}
                className='px-5 py-3 mb-5 bg-gray-100 border border-2'>
                <Row className='mb-1'>
                    <Col sm={4}>
                        <label className="fs-7 form-label fw-bold" htmlFor='requestName'>Request Name</label>
                        <Input
                            id="requestName"
                            placeholder="Enter Request Name"
                            onChange={onRequestNameChange}
                            value={filters.requestName || ""}
                        />
                    </Col>

                    <Col sm={4}>
                        <label className="fs-7 form-label fw-bold" htmlFor='dateRange'>Creation Date</label>
                        <DatePicker.RangePicker
                            id="dateRange"
                            presets={AntDRangePresets}
                            className='w-100'
                            value={filters.dateRange}
                            onChange={onDateRangeChange}
                        />
                    </Col>

                    <Col sm={4}>
                        <label className="fs-7 form-label fw-bold invisible d-block">Actions</label>
                        <Space>
                            <Button icon={<FilterOutlined rev={0} />}
                                onClick={toggleFilters}
                                className={showMoreFilters ? "btn-primary-outline" : ""}>
                                {
                                    showMoreFilters ? "Hide" : "More"
                                }
                            </Button>
                            <Button htmlType='submit' type='primary'>Apply</Button>
                            <Button htmlType='reset' className='btn-danger-outline'>Clear</Button>
                        </Space>
                    </Col>
                </Row>
                <Row className={showMoreFilters ? "" : "d-none"}>
                    <Col md={ShowApproverFilter ? 3 : 4}>
                        <label className="fs-7 form-label fw-bold" htmlFor='customerId'>Customer</label>
                        <Select
                            id="customerId"
                            showSearch
                            allowClear
                            mode="multiple"
                            className='w-100'
                            maxTagCount="responsive"
                            placeholder="Select Customer"
                            filterOption={false}
                            onChange={onCustomerChange}
                            notFoundContent={null}
                            fieldNames={{ label: 'customerName', value: 'customerId' }}
                            options={allCustomerListState.data || []}
                            loading={allCustomerListState.isFetching}
                            value={filters.customerId}
                        />
                    </Col>

                    <Col md={ShowApproverFilter ? 3 : 4}>
                        <label className="fs-7 form-label fw-bold" htmlFor='team'>Team</label>
                        <AntSelect id="team"
                            allowClear
                            showSearch
                            mode="multiple"
                            className='w-100'
                            maxTagCount="responsive"
                            loading={allUserListState.isFetching}
                            options={allUserListState.data || []}
                            filterOption={AntdOptionSearchFn}
                            placeholder="Select Team members"
                            value={filters.team}
                            onChange={onTeamChange}
                            fieldNames={{ label: "fullName", value: "userId" }}
                            isValid={(user: ActiveUserModel) => {
                                return _.intersection(user.roles, [Roles.CompanyAdmin, Roles.PricingAdmin, Roles.PricingMember]).length > 0
                            }}
                        />
                    </Col>

                    {
                        IsAdmin && filters.listingFilterType === ListingFilterType.Approvals &&
                        <Col md={ShowApproverFilter ? 3 : 4}>
                            <label className="fs-7 form-label fw-bold" htmlFor='approver'>Approver</label>
                            <AntSelect id="approver"
                                showSearch
                                mode="multiple"
                                className='w-100'
                                maxTagCount="responsive"
                                loading={allUserListState.isFetching}
                                options={allUserListState.data || []}
                                filterOption={AntdOptionSearchFn}
                                placeholder="Select Approver"
                                value={filters.approver}
                                onChange={onApproverChange}
                                fieldNames={{ label: "fullName", value: "userId" }}
                                isValid={(user: ActiveUserModel) => {
                                    return _.intersection(user.roles, [Roles.CompanyAdmin, Roles.PricingAdmin, Roles.PricingMember]).length > 0
                                }}
                            />
                        </Col>
                    }

                    <Col md={ShowApproverFilter ? 3 : 4}>
                        <label className="fs-7 form-label fw-bold" htmlFor='statusId'>Status</label>

                        {
                            filters.listingFilterType === ListingFilterType.Approvals ?
                                <Select
                                    id="statusId"
                                    mode="multiple"
                                    className="w-100"
                                    maxTagCount="responsive"
                                    placeholder="Select Status"
                                    options={[
                                        { label: "Pending Approvals", value: ApprovalStatusType.PendingApprovals },
                                        { label: "Approved", value: ApprovalStatusType.Approved }
                                    ]}
                                    onChange={onApprovalStatusChange}
                                    value={filters.approvalStatusId}
                                /> :
                                <Select
                                    id="statusId"
                                    allowClear
                                    mode="multiple"
                                    className='w-100'
                                    maxTagCount="responsive"
                                    placeholder="Select Status"
                                    options={allStatusList}
                                    onChange={onStatusChange}
                                    value={filters.statusId}
                                />
                        }
                    </Col>
                </Row>
            </form>

            <div className="ag-theme-alpine ag-theme-alpine-custom h-90 w-100">
                <AgGridReact
                    loading={getRequestsResult.isLoading}
                    ref={gridRef}
                    gridOptions={gridOptions} />
            </div>
        </Card>
    )
}

export { PricingRequests };

