import { Button, Card, Space, Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import { HasAnyRole, IsPricingAdmin } from "../../common/CommonFunctions";
import { Roles } from "../../common/Enums";
import { useAuth } from "../auth";
import { AutomationEfficiencyStats } from "./AutomationEfficiencyStats";
import { AutomationTrendStats } from "./AutomationTrendStats";
import { MyTaskStats } from "./MyTaskStats";
import { OpenQuotesStats } from "./OpenQuotesStats";
import { ProjectCompletedStats } from "./ProjectCompletedStats";
import { ProjectStatusOvertimeStats } from "./ProjectStatusOvertimeStats";
import { ProjectStatusStats } from "./ProjectStatusStats";
import { QuotesApproverStats } from "./QuotesApproverStats";
import { QuotesOwnerStats } from "./QuotesOwnerStats";
import { QuotesProcessedStats } from "./QuotesProcessedStats";
import { RecentProjects } from "./RecentProjects";
import { RecentQuotes } from "./RecentQuotes";

const DashboardTabs = {
    Projects: { name: "Projects", key: 'projects' },
    Pricing: { name: "Quotes", key: 'pricing' }
}

const Dashboard: React.FC = () => {
    const { user_metadata } = useAuth();
    const HasProjectAccess = HasAnyRole(user_metadata?.roles ?? [], [Roles.CompanyAdmin, Roles.ProjectOwner, Roles.Collaborator]),
        HasPricingAccess = HasAnyRole(user_metadata?.roles ?? [], [Roles.PricingAdmin, Roles.PricingMember]),
        IsProjectAdmin = HasAnyRole(user_metadata?.roles ?? [], [Roles.CompanyAdmin, Roles.ProjectOwner]);
    const [selectedTab, setSelectedTab] = useState<string | undefined>();

    const onTabChange = (activeKey: string) => {
        setSelectedTab(activeKey);
    }

    const visibleDashboardTabs = useMemo(() => {
        const tabs: TabsProps['items'] = [];

        if (HasProjectAccess)
            tabs.push({
                key: DashboardTabs.Projects.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/general/gen005.svg" className="svg-icon-2x" />
                    {DashboardTabs.Projects.name}
                </Space>,
                children: <ProjectTab />,
            });

        if (HasPricingAccess)
            tabs.push({
                key: DashboardTabs.Pricing.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/finance/fin010.svg" className="svg-icon-2x" />
                    {DashboardTabs.Pricing.name}
                </Space>,
                children: <PricingTab />,
            });

        return tabs;
    }, [])

    const tabBarActions = useMemo(() => {
        if (selectedTab === DashboardTabs.Projects.key) {
            return <Space>
                {
                    IsProjectAdmin &&
                    <Link to='/projects/add-project'>
                        <Button size="large" className="btn-gradient btn-gradient-blue">Create Project</Button>
                    </Link>
                }
                <Link to={'/projects/list'}>
                    <Button size="large" type="primary" ghost>My Projects</Button>
                </Link>
            </Space>
        }
        else if (selectedTab === DashboardTabs.Pricing.key) {
            return <Space>
                <Link to='/quotes/new-quote'>
                    <Button size="large" className="btn-gradient btn-gradient-blue">Create Quote</Button>
                </Link>
                <Link to='/quotes/list'>
                    <Button size="large" type="primary" ghost>My Quotes</Button>
                </Link>
            </Space>
        }
    }, [selectedTab])

    useEffect(() => {
        visibleDashboardTabs.length && setSelectedTab(visibleDashboardTabs[0].key)
    }, [])

    return (
        <>
            {/* <Row>
                {
                    (IsCompanyAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles)) &&
                    <Col sm={12} md={6} lg={4} className="mb-5">
                        <Card size="small" bordered={false}
                            className="border-start border-4 border-info h-100">
                            <Link to={'/projects/add-project'}>
                                <Card.Meta
                                    title="Create a new Project"
                                    description={
                                        <>
                                            <p>Rapidly generate responses for your RFP questions.</p>
                                            <Button type="primary" ghost>Create</Button>
                                        </>
                                    }
                                />
                            </Link>
                        </Card>
                    </Col>
                }
                {
                    HasProjectAccess &&
                    <Col sm={12} md={6} lg={4} className="mb-5">
                        <Card size="small" bordered={false}
                            className="border-start border-4 border-primary h-100">
                            <Link to={'/projects/list'}>
                                <Card.Meta
                                    title="My Projects"
                                    description={
                                        <>
                                            <p>Access your active projects.</p>
                                            <Button type="primary" ghost>View</Button>
                                        </>
                                    }
                                />
                            </Link>
                        </Card>
                    </Col>
                }
                {
                    HasPricingAccess &&
                    <Col sm={12} md={6} lg={4} className="mb-5">
                        <Card size="small" bordered={false}
                            className="border-start border-4 border-info h-100">
                            <Link to='/quotes/new-quote'>
                                <Card.Meta
                                    title="Create a new Quote"
                                    description={
                                        <Button type="primary" ghost>Create</Button>
                                    }
                                />
                            </Link>
                        </Card>
                    </Col>
                }
            </Row> */}

            <Card size="small">
                <Tabs size="large"
                    activeKey={selectedTab}
                    items={visibleDashboardTabs}
                    onChange={onTabChange}
                    tabBarExtraContent={tabBarActions}
                />
            </Card>
        </>
    )
}

export { Dashboard };

const ProjectTab: React.FC = () => {
    return (
        <>
            <Row>
                <Col sm={12} md={6} className="mb-5">
                    <MyTaskStats />
                </Col>

                <Col sm={12} md={6} className="mb-5">
                    <RecentProjects />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectStatusStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <AutomationEfficiencyStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectStatusOvertimeStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <AutomationTrendStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectCompletedStats />
                </Col>
            </Row>
        </>
    )
}

const PricingTab: React.FC = () => {
    const { user_metadata } = useAuth();
    const IsAdmin = IsPricingAdmin(user_metadata?.roles);

    return (
        <>
            <Row>
                <Col sm={12} md={6} className="mb-5">
                    <OpenQuotesStats />
                </Col>

                <Col sm={12} md={6} className="mb-5">
                    <RecentQuotes />
                </Col>
            </Row>

            {
                IsAdmin &&
                <Row>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesOwnerStats />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesOwnerStats isPriceChart={true} />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesApproverStats />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesApproverStats isPriceChart={true} />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesProcessedStats />
                    </Col>
                </Row>
            }
        </>
    )
}