import { Card, DatePicker, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useOwnerQuotesStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { AntDRangePresets } from "../../common/Constants";
import { DashboardStatsType } from "../../common/Enums";
import { localeCurrency, localeNumber } from "../../common/CommonFunctions";

type SeriesType = {
  name: string,
  data: number[]
}

type Props = {
  isPriceChart?: boolean
}

const ChartColors = ['#8c97c8', '#575c94', '#a76e6e', '#d2b7b7', '#ffc8c8', '#58afbf', '#f0e6ab', '#48bdb6', '#d3bae1', '#e39e83', '#77ad95', '#756f6f', '#979393', '#bab7b7'];

const QuotesOwnerStats: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<SeriesType[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const [triggerQuoteStats, quoteStatsResult] = useOwnerQuotesStatsMutation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => selectFilters(state, DashboardStatsType.AutomationTrend));

  const onRangeChange = (range: any) => {
    if (range) {
      let toDate: dayjs.Dayjs = range[1];
      range[1] = toDate.endOf('M');
    }

    setDateRange(range);
    setMonthCategories(range);

    dispatch(setFilters({
      type: DashboardStatsType.QuotesNumberOwned,
      filters: {
        range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
      }
    }));
  }

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [], dateRanges = _.cloneDeep(dateRange);

    while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
      _categories.push(dateRanges[0].format('MMM YY'));
      dateRanges[0] = dateRanges[0].add(1, "M");
    };

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerQuoteStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null
    });
  }

  const initOptions = (chartSeries: SeriesType[], chartCategories: string[]) => {

    setSeries(chartSeries);

    setOptions({
      colors: ChartColors,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'top',
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          columnWidth: categories.length <= 3 ? '25%' : '90%',
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          return props.isPriceChart ? localeCurrency(val) : localeNumber(val);
        },
        style: {
          fontSize: '12px',
          colors: ["#000"]
        }
      },
      xaxis: {
        type: 'category',
        categories: categories,
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: (val: number) => {
            return props.isPriceChart ? localeCurrency(val) : localeNumber(val);
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      fill: {
        opacity: 1
      }
    })
  }

  useEffect(() => {
    let dateRange = AntDRangePresets?.[0].value;

    if (filters?.range) {
      dateRange = [dayjs(filters.range[0]), dayjs(filters.range[1])];
    }

    onRangeChange(dateRange);

  }, []);

  useEffect(() => {
    loadStats(dateRange);
  }, [categories]);

  useEffect(() => {
    if (quoteStatsResult.requestId && !quoteStatsResult.isLoading &&
      quoteStatsResult.data && quoteStatsResult.data.success) {

      const chartSeries: SeriesType[] = [];

      let statsData = cloneDeep(quoteStatsResult.data.data)

      _.forEach(statsData, x => {
        // Set Month display label
        x.date = dayjs(`${x.year}-${x.month}-01`, 'YYYY-M-D').format("MMM YY");
      });

      // Group data based on User
      let stats = _.groupBy(statsData, x => x.userName);

      _.forOwn(stats, (data, k) => {
        let seriesDataDict = _.transform(data, (r: Record<string, number>, v) => r[v.date] = props.isPriceChart ? v.totalPrice : v.requestCount, {});

        chartSeries.push({
          name: k,
          data: categories.map(x => seriesDataDict[x] ?? 0)
        });
      });

      initOptions(chartSeries, categories);
    }
  }, [quoteStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={quoteStatsResult.isLoading}>

        <div className='d-flex align-items-center justify-content-between mb-5'>
          <span className='fs-3 fw-bold text-dark me-2 lh-1'>
            {
              props.isPriceChart ? 'Value of Quotes Owned' : 'No. of Quotes Owned'
            }
          </span>

          <DatePicker.RangePicker
            style={{maxWidth: '250px'}}
            presets={AntDRangePresets}
            picker="month"
            size="small"
            value={dateRange}
            onChange={onRangeChange}
          />
        </div>

        {
          options !== null &&
          <ReactApexChart options={options}
            series={series}
            type="bar" height={'300'} width={'100%'} />
        }
      </Spin>
    </Card>
  )
}

export { QuotesOwnerStats };

