import { DeleteOutlined, DownOutlined, FileProtectOutlined, RightOutlined, UserAddOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { App, Button, Card, Checkbox, CheckboxProps, Empty, Pagination, Popconfirm, Popover, Radio, RadioChangeEvent, Space, Tooltip } from "antd";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { assignQuestions, deleteQuestions, fetchAnswers, selectProjectParamsByKey, selectProjectSettings, selectSelectedSection, setParams, updateSection } from "../../../redux/ProjectsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useGetDatasetSelectListQuery } from "../../../redux/rtkquery/DatasetApi";
import { useAssignQuestionsMutation, useDeleteQuestionsMutation, useReorderQuestionsMutation } from "../../../redux/rtkquery/QuestionApi";
import { usePrevious } from '../../common/components/HelperComponents';
import { SortableCollapse, SortableDragHandle } from '../../common/components/SortableCollapse';
import { SectionModel } from "../../models";
import { AnswersRenderer } from "./AnswerRenderer";
import { AssignQuestionRenderer, QuestionRenderer } from "./QuestionRenderer";

type QuestionTabProps = {
  section: SectionModel
}

export const QuestionTabContent: React.FC<QuestionTabProps> = (props) => {
  const { section } = props;
  const [expendedQuestions, setExpendedQuestions] = useState<string[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<{ [questionId: number]: boolean }>({});
  const [visibleQuestions, setVisibleQuestions] = useState<{ [questionId: number]: boolean }>({});
  const [triggerReorderQuestions] = useReorderQuestionsMutation();
  const [triggerDeleteQuestions] = useDeleteQuestionsMutation();
  const [triggerAssignQuestions, assignQuestionsResult] = useAssignQuestionsMutation();
  const allDatasetListState = useGetDatasetSelectListQuery();
  const dispatch = useAppDispatch();
  const { modal } = App.useApp();
  const projectSettings = useAppSelector(state => selectProjectSettings(state));
  const prevExpendedQuestions = useRef<string[]>([]);
  const [pagination, setPagination] = useState([0, 10]);
  const [isApproverOpen, setIsApproverOpen] = useState(false);
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
  const prevQuestionIds = usePrevious(section.questionIds);
  const [selectAll, setSelectAll] = useState(false);
  const propLayoutOption = useAppSelector(state => selectProjectParamsByKey(state, "layoutOption"));
  const selectedSection = useAppSelector(state => selectSelectedSection(state));
  const [layoutOption, setLayoutOption] = useState(propLayoutOption);

  const layoutOptions = [
    { label: 'Expanded', value: 1 },
    { label: 'Compact', value: 0 },
  ];

  useEffect(() => {
    if (section.filteredQuestionIds.length) {
      onPageChange(1, pagination[1]);
    }
  }, [section.filteredQuestionIds])

  // Toggle First Question by default
  useEffect(() => {
    if (!propLayoutOption && !prevQuestionIds && section.filteredQuestionIds.length) {
      toggleQuestionPanel(section.filteredQuestionIds[0].toString(), false);
    }
  }, [section.questionIds])

  // Update question layout of this section
  useEffect(() => {
    if (selectedSection?.sectionId === section.sectionId && layoutOption !== propLayoutOption) {
      setLayoutOption(propLayoutOption);

      if (propLayoutOption) {
        let visibleQuestionIds = _.transform(visibleQuestions, (r: number[], v: boolean, k: string) => r.push(Number(k)), []) as number[];
        dispatch(fetchAnswers({ questionIds: visibleQuestionIds }));
        setExpendedQuestions(visibleQuestionIds.map(x => x.toString()));
      }
      else {
        setExpendedQuestions([]);
      }
    }
  }, [selectedSection, propLayoutOption])

  const onSelectAllChange: CheckboxProps['onChange'] = (e) => {
    setSelectAll(e.target.checked);

    if (e.target.checked) {
      setSelectedQuestions(_.transform(section.questionIds, (r: any, v: any) => r[v] = true, {}));
    }
    else {
      setSelectedQuestions({});
    }
  }

  // Update Layout option
  const onLayoutChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setParams({ params: { layoutOption: value } }));
  }

  const beforeSortStart = (params: any) => {
    prevExpendedQuestions.current = expendedQuestions;
    setExpendedQuestions([]);
  }

  const onSortEnd = ({ oldIndex, newIndex }: any) => {

    const questionIds = [...section.questionIds];
    const [removed] = questionIds.splice(oldIndex, 1);
    questionIds.splice(newIndex, 0, removed);

    let filteredQuestionIds: number[] = [];

    // Order question ids in filtered array
    questionIds.forEach((x, i) => {
      if (section.filteredQuestionIds.indexOf(x) >= 0) {
        filteredQuestionIds.push(x);
      }
    });

    dispatch(updateSection({
      ...section,
      questionIds,
      filteredQuestionIds
    }));

    setExpendedQuestions(prevExpendedQuestions.current);

    triggerReorderQuestions({
      projectId: props.section.projectId,
      sectionId: section.sectionId,
      questionIds
    });
  }

  const toggleQuestionPanel = (questionId: string, expanded: boolean) => {
    if (expanded)
      setExpendedQuestions(expendedQuestions.filter(x => x !== questionId))
    else {
      dispatch(fetchAnswers({ questionIds: [Number(questionId)] }));
      expendedQuestions.push(questionId);
      setExpendedQuestions([...expendedQuestions]);
    }
  }

  const onQuestionSelectChange = (questionId: number, selected: boolean) => {
    if (selected)
      selectedQuestions[questionId] = selected;
    else
      delete selectedQuestions[questionId];

    setSelectedQuestions({ ...selectedQuestions });
    setSelectAll(_.keys(selectedQuestions).length === section.questionIds.length);
  }

  const onDeleteQuestions = () => {
    modal.confirm({
      title: 'Confirm deletion',
      content: <>
        <p>Are you sure you would like to delete the seleced questions?</p>
        <p>Note: Only the following questions will be deleted:</p>
        <ol>
          <li>Questions assigned to you.</li>
          <li>Questions where you are the approver.</li>
        </ol>
        <p className='text-danger fw-bold'>This action cannot be undone.</p>
      </>,
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: () => deleteSelectedQuestions()
    })
  }

  const deleteSelectedQuestions = async (questionId?: number) => {
    const questionIds = questionId ? [questionId] : _.keys(selectedQuestions).map(x => Number(x));

    let response = await triggerDeleteQuestions({
      projectId: props.section.projectId,
      questionIds
    });

    if ('data' in response && response.data.success) {
      dispatch(deleteQuestions({
        projectId: section.projectId,
        sectionId: section.sectionId,
        questionIds
      }))
    }
  }

  const setVisibleQuestionsValue = (page: number, pageSize: number) => {
    let startIndex = (page - 1) * pageSize;
    let visibleQuestionIds = _.slice(section.filteredQuestionIds, startIndex, startIndex + pageSize);

    setVisibleQuestions(_.transform(visibleQuestionIds, (r: { [questionId: number]: boolean }, v) => r[v] = true, {}));

    if (propLayoutOption) {
      dispatch(fetchAnswers({ questionIds: visibleQuestionIds }));
      setExpendedQuestions(visibleQuestionIds.map(x => x.toString()));
    }
  }

  const onPageChange = (page: number, pageSize: number) => {
    setPagination([page, pageSize]);
    setVisibleQuestionsValue(page, pageSize);
  }

  const onSaveApprovers = (userIds: number[], replaceExisting?: boolean) => {
    let questionIds = _.keys(selectedQuestions).map(x => Number(x));

    triggerAssignQuestions({
      assignType: 1,
      projectId: props.section.projectId,
      sectionId: section.sectionId,
      questionIds,
      userIds,
      replaceExisting: replaceExisting || false,
    }).then(response => {

      setIsApproverOpen(false);

      if ("error" in response) {
        // log/show error
        return;
      }

      dispatch(assignQuestions({
        assignType: 1,
        projectId: section.projectId,
        sectionId: section.sectionId,
        questionIds,
        userIds,
        replaceExisting: replaceExisting || false,
      }));
    });
  }

  const onSaveAssignees = (userIds: number[], replaceExisting?: boolean) => {

    let questionIds = _.keys(selectedQuestions).map(x => Number(x));

    triggerAssignQuestions({
      assignType: 0,
      projectId: props.section.projectId,
      sectionId: props.section.sectionId,
      questionIds,
      userIds,
      replaceExisting: replaceExisting || false,
    }).then(response => {

      setIsAssigneeOpen(false);

      if ("error" in response) {
        // log/show error
        return;
      }

      dispatch(assignQuestions({
        assignType: 0,
        projectId: section.projectId,
        sectionId: section.sectionId,
        questionIds,
        userIds,
        replaceExisting: replaceExisting || false,
      }));
    });
  }

  return (
    <Card loading={!section.questionIds}
      title={<h2>{section.sectionName}</h2>}
      extra={
        !_.isEmpty(selectedQuestions) ?
          <Space size='small'>
            <Tooltip title="Assign Questions">
              <Popover
                destroyTooltipOnHide
                placement="left"
                open={isAssigneeOpen}
                content={
                  <AssignQuestionRenderer
                    onSave={onSaveAssignees}
                    showReplaceExisting={true}
                    loading={assignQuestionsResult.isLoading}
                    userList={projectSettings?.teamMembers || []}
                  />
                }
                title="Assign Question"
                trigger="click"
                onOpenChange={(visible) => setIsAssigneeOpen(visible && section.filteredQuestionIds.length > 0)}
              >
                <Button shape="circle" type='primary' ghost
                  icon={<UserAddOutlined rev={0} />}
                  disabled={section.filteredQuestionIds.length === 0} />
              </Popover>
            </Tooltip>
            <Tooltip title="Assign Approvers">
              <Popover
                destroyTooltipOnHide
                placement="left"
                open={isApproverOpen}
                popupVisible={section.filteredQuestionIds.length > 0}
                content={
                  <AssignQuestionRenderer
                    onSave={onSaveApprovers}
                    showReplaceExisting={true}
                    loading={assignQuestionsResult.isLoading}
                    userList={projectSettings?.approvers || []}
                  />
                }
                title="Assign Approvers"
                trigger="click"
                onOpenChange={(visible) => setIsApproverOpen(visible && section.filteredQuestionIds.length > 0)}
              >
                <Button shape="circle" type='primary' ghost
                  icon={<FileProtectOutlined rev={undefined} />}
                  disabled={section.filteredQuestionIds.length === 0}
                />
              </Popover>
            </Tooltip>
            <Tooltip title="Delete Questions">
              <Button shape="circle" danger ghost
                onClick={onDeleteQuestions}
                icon={<DeleteOutlined rev={0} />}
                disabled={section.filteredQuestionIds.length === 0}
              />
            </Tooltip>
          </Space> : null
      }>

      {
        section.filteredQuestionIds.length > 0 &&
        <Row className='mb-5'>
          <Col sm={6}>
            <Checkbox onChange={onSelectAllChange}
              checked={selectAll}>
              Select All
            </Checkbox>
          </Col>
          <Col sm={6} className='text-end'>
            <Radio.Group size='small'
              options={layoutOptions}
              onChange={onLayoutChange}
              value={layoutOption || 0}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
        </Row>
      }

      <SortableCollapse
        className="questions-collapse"
        collapsible="icon"
        activeKey={expendedQuestions}
        expandIconPosition="end"
        useDragHandle
        lockAxis="y"
        pressDelay={200}
        onSortEnd={onSortEnd}
        updateBeforeSortStart={beforeSortStart}
        shouldCancelStart={() => section.filteredQuestionIds.length === 1}

        panels={
          _.map(section.filteredQuestionIds, questionId => {
            let expanded = expendedQuestions.indexOf(questionId.toString()) >= 0;
            return {
              key: questionId,
              showArrow: false,
              className: visibleQuestions[questionId] ? '' : 'invisible',
              header: <QuestionRenderer questionId={questionId}
                sectionId={section.sectionId}
                selected={selectedQuestions[questionId] || false}
                datasetList={allDatasetListState.data || []}
                onSelectChange={onQuestionSelectChange}
                extra={
                  <Space>
                    <Popconfirm
                      title="Delete Question"
                      description="Are you sure to delete this question?"
                      onConfirm={() => deleteSelectedQuestions(questionId)}
                      okText="Yes"
                      cancelText="No"
                      placement='topLeft'
                    >
                      <DeleteOutlined rev={0} title='Delete Question' />
                    </Popconfirm>

                    {
                      section.questionIds?.length === section.filteredQuestionIds.length ? <SortableDragHandle /> : null
                    }
                    {
                      <Icon rev={0}
                        onClick={() => toggleQuestionPanel(questionId.toString(), expanded)}
                        className='cursor-pointer'
                        component={(expanded ? DownOutlined : RightOutlined) as React.ForwardRefExoticComponent<any>} />
                    }
                  </Space>
                }
              />,
              children: <AnswersRenderer questionId={questionId} sectionId={section.sectionId} />
            }
          }
          )
        }
      />

      {
        section.filteredQuestionIds.length > 0 &&

        <div className="text-center">
          <Pagination
            hideOnSinglePage={section.filteredQuestionIds.length <= 10}
            showSizeChanger
            total={section.filteredQuestionIds.length}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            current={pagination[0]}
            pageSize={pagination[1]}
            onChange={onPageChange}
          />
        </div>
      }

      {
        section.questionIds?.length > 0 &&
        section.filteredQuestionIds.length === 0 &&
        <Empty description="No Questions available based on applied filters" />
      }

      {
        section.questionIds?.length === 0 &&
        <Empty description="No Questions added yet in this section." />
      }

    </Card>
  )
}