import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import _ from 'lodash';
import { useMemo } from 'react';

export interface ExtendedSelectProps extends SelectProps {
    keepInvalid?: boolean,
    isValid: (option: any) => boolean | string | null,
    options: any[]
}

const AntSelect: React.FC<ExtendedSelectProps> = (props) => {
    const { keepInvalid, isValid, options, ...rest } = props;

    const visibleOptions = useMemo(() => {
        const _options: DefaultOptionType[] = [];

        _.forEach(props.options, x => {
            let valid = props.isValid(x),
                label: string = x[props.fieldNames?.label ?? "label"];

            let option: DefaultOptionType = {
                label,
                value: x[props.fieldNames?.value ?? "value"],
                labelString: label,
                disabled: valid !== true
            }

            if (valid === true || valid === null)
                _options.push(option);
            else if (option.disabled && (keepInvalid || props.value === option.value ||
                (_.isArray(props.value) && props.value.indexOf(option.value) > -1))) {
                option.disabled = false;
                option.label = <>{label} <span className='text-danger'>({valid})</span></>;
                _options.push(option);
            }
        });

        return _options;

    }, [options]);

    return (
        <Select {...rest}
            options={visibleOptions}
            fieldNames={{label: "label", value: "value"}}
        />
    )
}

export default AntSelect;