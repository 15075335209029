import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { DefaultOptionType } from 'antd/es/select';
import _, { cloneDeep } from 'lodash';
import { AnswerJobStatus, ProjectStatus, QuestionFileStatus, QuestionStatus, QuestionUserRole, Roles } from '../app/common/Enums';
import { AnswerModel, AttachmentModel, ProjectFilterModel, ProjectModel, QuestionFileModel, QuestionFileUploadModel, QuestionModel, SectionModel, SectionSelectModel, UserMetaDataModel } from '../app/models';
import { RootState } from './store';

type QuestionsType = {
    [sectionId: number | string]: {
        [questionId: number]: QuestionModel
    }
}

type QuestionAnswersJobStatusType = {
    [questionId: number]: AnswerJobStatus
}

type AnswersType = {
    [questionId: number]: AnswerModel[]
}

type SectionsType = {
    [sectionId: number]: SectionModel
}

type ProjectSettingsType = {
    status: ProjectStatus,
    isProjectOpen: boolean,
    hasFullAccess: boolean,
    currentUser: number,
    approvers: DefaultOptionType[],
    teamMembers: DefaultOptionType[]
}

interface SliceState {
    project: ProjectModel | null,
    questions: QuestionsType,
    answers: AnswersType,
    sections: SectionsType,
    settings: ProjectSettingsType | null,
    filters: ProjectFilterModel,
    params: Record<string, any>,
    questionAnswersJobStatus: QuestionAnswersJobStatusType,
    fetchAnswerQuestionIds: number[]
}

const initialState: SliceState = {
    project: null,
    questions: {},
    answers: {},
    sections: {},
    settings: null,
    params: {},
    questionAnswersJobStatus: {},
    fetchAnswerQuestionIds: [],
    filters: { approvers: [], assignees: [], myQuestions: false, statuses: [] }
}

export const ProjectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addProject(state, { payload }: PayloadAction<{ project: ProjectModel, user: UserMetaDataModel | null }>) {
            let { project, user } = payload;

            // Remove existing project details
            removeProject();

            if (project.sections && project.sections.length) {
                // Add new sections
                state.sections = _.transform(cloneDeep(project.sections), (r: { [sectionId: number]: SectionModel }, v) => {
                    //v.questionIds = []; // Don't init this, using for loader
                    v.filteredQuestionIds = [];
                    r[v.sectionId] = v;
                }, {});

                state.params = { selectedSectionId: project.sections[0].sectionId }
            }

            state.project = cloneDeep(project);
            setProjectSettings(state, user);
        },
        updateProject(state, { payload }: PayloadAction<{ project: ProjectModel, user: UserMetaDataModel | null }>) {
            let { project: _project } = payload;
            const project = state.project as ProjectModel;

            project.projectName = _project.projectName;
            project.customerId = _project.customerId;
            project.customerName = _project.customerName;
            project.dueDate = _project.dueDate;
            project.team = _project.team;
            project.approvers = _project.approvers;

            if (project.projectOwnerId !== _project.projectOwnerId ||
                project.statusId !== _project.statusId) {

                project.statusId = _project.statusId;
                project.projectOwnerId = _project.projectOwnerId;
                setProjectSettings(state, payload.user);
            }
        },
        removeProject(state) {

            if (state.project) {
                // Remove existing project data
                state.project = null;

                // Remove existing questions for project
                state.questions = {};
                // Remove existing answers for project
                state.answers = {};
                // Remove existing sections for project
                state.sections = {};
                // Remove existing settings on project for User
                state.settings = null;
                // Remove existing filters on project
                state.filters = { approvers: [], assignees: [], myQuestions: false, statuses: [] };
                // Remove params for project
                state.params = {};
                // Remove In-Progress Questions for project
                state.questionAnswersJobStatus = {};
                // Remove Question Ids for fetching answers for project
                state.fetchAnswerQuestionIds = [];
            }
        },
        addQuestionFile(state, { payload }: PayloadAction<QuestionFileUploadModel>) {
            const project = state.project as ProjectModel;
            let section = state.sections[payload.sectionId || 0];

            if (!section) {
                let newSection: SectionModel = {
                    projectId: project.projectId,
                    sectionId: payload.sectionId || 0,
                    sectionName: payload.sectionName || "",
                    displayOrder: Number.MAX_SAFE_INTEGER,
                    createdDate: payload.createdDate,
                    totalQuestion: 0,
                    totalAccepted: 0,
                    totalSubmitted: 0,
                    totalUnAnswered: 0,
                    exportInOriginalFormat: payload.exportInOriginalFormat || false,
                    questionIds: [],
                    filteredQuestionIds: []
                }

                state.sections[newSection.sectionId] = newSection;
            }

            project.questionFiles ??= [];
            project.questionFiles = [payload, ...project.questionFiles];
        },
        updateQuestionFiles(state, { payload }: PayloadAction<{ files: QuestionFileModel[], replace: boolean }>) {
            const project = state.project as ProjectModel;
            const questionFiles = project.questionFiles || [];

            if (questionFiles.length) {
                _.forEach(payload.files, file => {
                    let index = _.findIndex(questionFiles, x => x.importId === file.importId);
                    let questionFile = questionFiles[index];

                    if (index >= 0) {
                        if (payload.replace) {
                            questionFiles[index] = {
                                ...questionFile,
                                ...file
                            }
                        }
                        // Replace only if marked as Under-review or Completed and not marked previously
                        else if (
                            (questionFile.statusId !== QuestionFileStatus.Pending || questionFile.statusId !== QuestionFileStatus.InProgress) &&
                            (file.statusId === QuestionFileStatus.Completed || file.statusId === QuestionFileStatus.UnderReview)
                        ) {
                            questionFiles[index] = {
                                ...questionFile,
                                ...file
                            }
                        }
                    }
                })
            }
        },
        addSectionQuestions(state, { payload }: PayloadAction<{ projectId: number, questions: QuestionModel[] }>) {
            const project = state.project as ProjectModel;
            let sectionId = payload.questions[0].sectionId;
            let section = state.sections[sectionId];

            if (section) {
                section.totalQuestion = section.questionIds.length + payload.questions.length;
            }
            else {
                let question = payload.questions[0];

                let newSection: SectionModel = {
                    projectId: project.projectId,
                    sectionId: sectionId,
                    sectionName: question.sectionName || "",
                    displayOrder: Number.MAX_SAFE_INTEGER,
                    createdDate: question.createdDate,
                    totalQuestion: payload.questions.length,
                    totalAccepted: 0,
                    totalSubmitted: 0,
                    totalUnAnswered: 0,
                    exportInOriginalFormat: false,
                    questionIds: [],
                    filteredQuestionIds: []
                }

                section = state.sections[newSection.sectionId] = newSection;
            }

            let questions = state.questions[sectionId];

            if (!questions)
                questions = state.questions[sectionId] = {};

            let quesitonAnswersInProgress: QuestionAnswersJobStatusType = {};

            _.forEach(payload.questions, question => {
                setQuestionUserRole(state, question);
                question.answerJobStatus = AnswerJobStatus.Processing;
                questions[question.questionId] = question;

                quesitonAnswersInProgress[question.questionId] = AnswerJobStatus.Processing;
            });

            // Keep Questions in display order
            let questionOrder: { questionId: number, displayOrder: number }[] = [];
            _.forOwn(questions, question => questionOrder.push({ displayOrder: question.displayOrder, questionId: question.questionId }));

            section.questionIds = _.map(_.orderBy(questionOrder, q => q.displayOrder), x => x.questionId);

            state.questionAnswersJobStatus = { ...state.questionAnswersJobStatus, ...quesitonAnswersInProgress };

            applyFilters(state);
        },
        addProjectQuestions(state, { payload }: PayloadAction<{ projectId: number, questions: QuestionModel[] }>) {

            let filters = state.filters;
            let settings = state.settings as ProjectSettingsType;

            if (payload.questions.length) {

                // Group questions by section ids
                let sectionQuestions = _.groupBy(_.orderBy(cloneDeep(payload.questions), x => x.displayOrder), x => x.sectionId);

                let quesitonAnswersInProgress: QuestionAnswersJobStatusType = {};

                _.forOwn(sectionQuestions, (questions, sectionId) => {

                    let counts: any = {
                        questionIds: [],
                        totalQuestion: 0,
                        totalUnAnswered: 0,
                        totalSubmitted: 0,
                        totalAccepted: 0
                    };

                    // Add quesitons and initialize section stats
                    state.questions[sectionId] = _.transform(questions, (r: { [questionId: number]: QuestionModel }, v) => {

                        setQuestionUserRole(state, v);

                        r[v.questionId] = v;

                        counts.questionIds.push(v.questionId);
                        counts.totalQuestion += 1;

                        if (v.answerJobStatus === AnswerJobStatus.Processing)
                            quesitonAnswersInProgress[v.questionId] = AnswerJobStatus.Processing;

                        switch (v.statusId) {
                            case QuestionStatus.Unanswered:
                            case QuestionStatus.Drafted:
                                counts.totalUnAnswered += 1;
                                break;
                            case QuestionStatus.Submitted:
                            case QuestionStatus.RequestChanges:
                                counts.totalSubmitted += 1;
                                break;
                            case QuestionStatus.Accepted:
                                counts.totalAccepted += 1;
                                break;
                        }
                    }, {});

                    let section = state.sections[Number(sectionId)];

                    if (section) {
                        state.sections[Number(sectionId)] = {
                            ...section,
                            ...counts
                        }
                    }
                });

                if (settings && !settings.hasFullAccess && filters) {
                    filters.myQuestions = payload.questions.some(x => (x.assignees && x.assignees.indexOf(settings.currentUser) > -1) ||
                        (x.approvers && x.approvers.indexOf(settings.currentUser) > -1));
                }

                state.questionAnswersJobStatus = quesitonAnswersInProgress;
            }

            // Initialize questionIds array of all remaining sections so loader can be disabled
            _.forOwn(state.sections, x => {
                if (!state.questions[x.sectionId])
                    x.questionIds = [];
            });

            applyFilters(state);
        },
        assignQuestions(state, { payload }: PayloadAction<{ projectId: number, sectionId: number, questionIds?: number[], userIds: number[], replaceExisting: boolean, assignType: 0 | 1 }>) {
            let questions = state.questions[payload.sectionId];
            let section = state.sections[payload.sectionId];

            if (!_.isNil(questions) && section) {
                _.forOwn(questions, x => {

                    if (payload.questionIds?.length && payload.questionIds.indexOf(x.questionId) === -1)
                        return;

                    if (section.filteredQuestionIds.length && section.filteredQuestionIds.indexOf(x.questionId) > -1) {
                        if (payload.assignType === 0 && (!x.assignees?.length || payload.replaceExisting)) {
                            x.assignees = payload.userIds;
                        }

                        if (payload.assignType === 1 && (!x.approvers?.length || payload.replaceExisting)) {
                            x.approvers = payload.userIds;
                        }

                        setQuestionUserRole(state, x);
                    }
                });
            }
        },
        deleteQuestions(state, { payload }: PayloadAction<{ projectId: number, sectionId: number, questionIds: number[] }>) {
            let questions = state.questions[payload.sectionId];
            let section = state.sections[payload.sectionId];

            if (!_.isEmpty(questions) && section) {
                // Remove questions from collection
                _.forEach(payload.questionIds, x => {
                    delete questions[x];
                });

                // Remove questionIds from section
                section.questionIds = section.questionIds.filter(x => payload.questionIds.indexOf(x) < 0);

                // Update section stats counts for display on project top area
                setSectionStatCounts(state, section.sectionId);

                applyFilters(state);
            }
        },
        updateQuestion(state, { payload }: PayloadAction<QuestionModel>) {
            let question = state.questions[payload.sectionId]?.[payload.questionId];

            if (question) {
                question.datasetId = payload.datasetId;
                question.responseFormat = payload.responseFormat;
                question.answerJobStatus = payload.answerJobStatus;
                question.libraryStatusId = payload.libraryStatusId;

                if (question.statusId !== payload.statusId) {

                    if (payload.statusId === QuestionStatus.Submitted || payload.statusId === QuestionStatus.Accepted) {
                        let answers = state.answers[question.questionId];

                        // Remove request change comment from answers
                        _.forEach(answers, x => {
                            x.comment = null;
                            x.commentDate = null;
                            x.commentBy = null;
                        });
                    }

                    question.statusId = payload.statusId;

                    // Update section stats counts for display on project top area
                    setSectionStatCounts(state, question.sectionId);
                }

                if (question.answerJobStatus === AnswerJobStatus.Processing)
                    state.questionAnswersJobStatus[question.questionId] = question.answerJobStatus;
            }
        },
        fetchAnswers(state, { payload }: PayloadAction<{ questionIds: number[] }>) {

            // Fetch answers for only those which not loaded yet and not exists in fetch queue
            let questionIds = _.filter(payload.questionIds, x => !state.answers[x] && state.fetchAnswerQuestionIds.indexOf(x) === -1);

            if (questionIds.length)
                state.fetchAnswerQuestionIds = [...state.fetchAnswerQuestionIds, ...questionIds];
        },
        addFetchedAnswers(state, { payload }: PayloadAction<{ answers: AnswerModel[], documents: AttachmentModel[], questionIds: number[] }>) {
            let answersDict = _.groupBy(cloneDeep(payload.answers), x => x.questionId);
            let documentsDict = _.groupBy(cloneDeep(payload.documents), x => x.questionId);

            _.forEach(payload.questionIds, questionId => {
                let answers = answersDict[questionId] || [];
                let documents = documentsDict[questionId] || [];

                if (answers.length) {
                    let answer = answers[0];
                    if (answer.sectionId && state.questions[answer.sectionId] &&
                        state.questions[answer.sectionId][answer.questionId]) {

                        if (documents.length) {
                            // Add documents in suggested answers object
                            _.forEach(answers, x => {
                                x.documents = _.remove(documents, d => d.answerId === x.answerId);
                            });
                        }

                        // Add Answers in state
                        state.answers[Number(questionId)] = answers;
                    }
                }
                else {
                    state.answers[Number(questionId)] = [];
                }
            });

            let questionIds = _.filter(state.fetchAnswerQuestionIds, x => payload.questionIds.indexOf(x) === -1);
            state.fetchAnswerQuestionIds = [...questionIds];
        },
        addGeneratedAnswers(state, { payload }: PayloadAction<{ answers: AnswerModel[], documents: AttachmentModel[] }>) {
            let answersDict = _.groupBy(cloneDeep(payload.answers), x => x.questionId);
            let documentsDict = _.groupBy(cloneDeep(payload.documents), x => x.questionId);

            _.forOwn(answersDict, (answers, questionId) => {
                let answer = answers[0];
                if (answer.sectionId && state.questions[answer.sectionId]) {
                    let question = state.questions[answer.sectionId][answer.questionId];

                    if (question && question.answerJobStatus === AnswerJobStatus.Processing &&
                        answer.answerJobStatus === AnswerJobStatus.Idle) {
                        let documents = documentsDict[questionId] || [];

                        if (documents.length) {
                            // Add documents in suggested answers object
                            _.forEach(answers, x => {
                                x.documents = _.remove(documents, d => d.answerId === x.answerId);
                            });
                        }

                        // Add Answers in state
                        state.answers[Number(questionId)] = answers;
                        // Remove question from processing list
                        delete state.questionAnswersJobStatus[Number(questionId)];
                        // Update Answer Job status in Question to update loader state
                        question.answerJobStatus = answer.answerJobStatus;
                    }
                }
            });
        },
        replaceAnswers(state, { payload }: PayloadAction<{ answers: AnswerModel[], documents: AttachmentModel[], questionStatus?: QuestionStatus }>) {
            let answer = payload.answers[0];
            if (answer.sectionId && state.questions[answer.sectionId]) {
                let question = state.questions[answer.sectionId][answer.questionId];

                if (question) {
                    let answers = cloneDeep(payload.answers);
                    let documents = cloneDeep(payload.documents);

                    if (documents.length) {
                        // Add documents in suggested answers object
                        _.forEach(answers, x => {
                            x.documents = _.remove(documents, d => d.answerId === x.answerId);
                        });
                    }

                    // Set Answers in store
                    state.answers[answer.questionId] = answers;

                    if (payload.questionStatus)
                        question.statusId = payload.questionStatus;

                    if (answer.answerJobStatus === AnswerJobStatus.Idle)
                        delete state.questionAnswersJobStatus[answer.questionId];

                    // Update Answer Job status in Question to update loader state
                    question.answerJobStatus = answer.answerJobStatus;
                }
            }
        },
        saveAnswer(state, { payload }: PayloadAction<{ answer: AnswerModel, questionStatus?: QuestionStatus, replaceDocs?: boolean }>) {
            let answer = cloneDeep(payload.answer);
            let allAnswers = state.answers[answer.questionId];

            if (!allAnswers)
                allAnswers = state.answers[answer.questionId] = [];

            if (answer.sectionId && state.questions[answer.sectionId]) {

                let index = _.findIndex(allAnswers, x => x.answerId === answer.answerId);

                if (index >= 0) {
                    if (!payload.replaceDocs) // Keep previous docs
                        answer.documents = allAnswers[index].documents;

                    allAnswers[index] = answer;
                }
                else {
                    // Composed or Library Search case

                    // Remove all existing suggested answers
                    _.remove(allAnswers, x => x.answerNo === answer.answerNo);

                    // Push composed answer
                    allAnswers.push(answer);
                }

                let question = state.questions[answer.sectionId][answer.questionId];

                if (question && payload.questionStatus)
                    question.statusId = payload.questionStatus;
            }
        },
        deleteAnswer(state, { payload }: PayloadAction<AnswerModel>) {
            let allAnswers = state.answers[payload.questionId];

            if (allAnswers && allAnswers.length) {
                // Remove answer
                _.remove(allAnswers, x => x.answerId === payload.answerId);

                // Update Question Status
                if (payload.isSelected) {
                    let question = state.questions[payload.sectionId || 0]?.[payload.questionId];
                    question.statusId = _.find(allAnswers, x => x.isSelected) ? QuestionStatus.Drafted : QuestionStatus.Unanswered;
                }
            }
        },
        updateSection(state, { payload }: PayloadAction<SectionModel>) {
            let section = state.sections[payload.sectionId];

            if (section) {
                state.sections[payload.sectionId] = {
                    ...section,
                    ...payload
                }
            }
        },
        mergeSections(state, { payload }: PayloadAction<{ projectId: number, oldSectionId: number, newSectionId: number }>) {
            let newSection = state.sections[payload.newSectionId];
            let oldSection = state.sections[payload.oldSectionId];

            if (newSection && oldSection) {
                if (oldSection.questionIds.length) {
                    newSection.questionIds.push(...oldSection.questionIds);

                    _.forEach(oldSection.questionIds, id => {
                        let question = state.questions[oldSection.sectionId][id];
                        if (question) {
                            question.sectionId = newSection.sectionId;

                            if (!state.questions[newSection.sectionId])
                                state.questions[newSection.sectionId] = {};

                            state.questions[newSection.sectionId][id] = question;
                            delete state.questions[oldSection.sectionId][id];
                        }
                    });
                }
                delete state.sections[payload.oldSectionId];

                applyFilters(state);
            }
        },
        deleteSection(state, { payload }: PayloadAction<SectionModel>) {
            const project = state.project as ProjectModel;
            let section = state.sections[payload.sectionId];

            if (project && section) {
                project.sectionIds = _.filter(project.sectionIds, x => x !== payload.sectionId);
                project.questionFiles = _.filter(project.questionFiles, x => x.sectionId !== payload.sectionId);
                delete state.sections[payload.sectionId];
                delete state.questions[payload.sectionId];
            }
        },
        setFilters(state, { payload }: PayloadAction<{ filters: ProjectFilterModel }>) {
            let { filters } = payload;
            state.filters = filters;

            applyFilters(state);
        },
        setParams(state, { payload }: PayloadAction<{ params: Record<string, any> }>) {
            let { params } = payload;

            let projectParams = state.params;

            if (!projectParams) {
                projectParams = state.params = {};
            }

            _.forOwn(params, (value, key) => {
                projectParams[key] = value
            })
        },
        setProjectTeamAndApprovers(state, { payload }: PayloadAction<{ team?: DefaultOptionType[], approvers?: DefaultOptionType[] }>) {
            let { team, approvers } = payload;
            let projectSettings = state.settings;

            if (team && projectSettings) {
                projectSettings.teamMembers = team;
            }

            if (approvers && projectSettings) {
                projectSettings.approvers = approvers;
            }
        }
    },
});

const setSectionStatCounts = (state: SliceState, sectionId: number) => {

    let section = state.sections[sectionId];

    if (section) {
        let questions = state.questions[section.sectionId];

        let counts: any = {
            totalUnAnswered: 0,
            totalSubmitted: 0,
            totalAccepted: 0
        };

        _.forOwn(questions, question => {
            switch (question.statusId) {
                case QuestionStatus.Unanswered:
                case QuestionStatus.Drafted:
                    counts.totalUnAnswered += 1;
                    break;
                case QuestionStatus.Submitted:
                case QuestionStatus.RequestChanges:
                    counts.totalSubmitted += 1;
                    break;
                case QuestionStatus.Accepted:
                    counts.totalAccepted += 1;
                    break;
            }
        });

        state.sections[section.sectionId] = {
            ...section,
            ...counts
        }
    }
}

const setQuestionUserRole = (state: SliceState, question: QuestionModel) => {
    let settings = state.settings;

    if (settings) {
        if (_.indexOf(question.assignees, settings.currentUser) > -1) {
            question.userAccessRole = QuestionUserRole.Assignee;
            return;
        }

        if (_.indexOf(question.approvers, settings.currentUser) > -1) {
            question.userAccessRole = QuestionUserRole.Approver;
            return;
        }
    }

    question.userAccessRole = QuestionUserRole.TeamMember;
}

const setProjectSettings = (state: SliceState, user: UserMetaDataModel | null) => {
    const project = state.project as ProjectModel;

    let settings = {
        status: project.statusId,
        isProjectOpen: project.statusId === ProjectStatus.Open,
        hasFullAccess: false,
        currentUser: user?.userId || 0,
        approvers: [] as DefaultOptionType[],
        teamMembers: [] as DefaultOptionType[]
    }

    // Company Admin and Project Owner has full permissions
    if (user && (user.roles.indexOf(Roles.CompanyAdmin) > -1 ||
        (user.roles.indexOf(Roles.ProjectOwner) > -1 && project.projectOwnerId === user.userId))) {
        settings.hasFullAccess = true;
    }

    state.settings = settings;
}

const applyFilters = (state: SliceState) => {

    const settings = state.settings;
    const filters = state.filters as ProjectFilterModel;

    let hasFilter = filters.searchText || filters.statuses.length || filters.myQuestions || filters.approvers.length || filters.assignees.length;

    _.forOwn(state.sections, section => {
        if (!hasFilter) {
            section.filteredQuestionIds = section.questionIds;
            return;
        }

        section.filteredQuestionIds = section.questionIds.filter(questionId => {
            let question = state.questions[section.sectionId]?.[questionId];
            let keepQuestion = true;
            let filterUnassigned = filters.assignees.indexOf(0) > -1;
            let filterNoApprovers = filters.approvers.indexOf(0) > -1;

            if (question && settings) {
                if (filters.myQuestions)
                    keepQuestion = ((question.assignees && question.assignees.indexOf(settings.currentUser) > -1) ||
                        (question.approvers && question.approvers.indexOf(settings.currentUser) > -1)) ? true : false;

                if (keepQuestion && filters.statuses.length)
                    keepQuestion = filters.statuses.indexOf(question.statusId) !== -1;

                if (keepQuestion && filters.assignees.length)
                    keepQuestion = (filterUnassigned && _.isEmpty(question.assignees)) || (question.assignees && question.assignees.some(userId => filters.assignees.indexOf(userId) > -1) ? true : false);

                if (keepQuestion && filters.approvers.length)
                    keepQuestion = (filterNoApprovers && _.isEmpty(question.approvers)) || (question.approvers && question.approvers.some(userId => filters.approvers.indexOf(userId) > -1) ? true : false);

                if (keepQuestion && filters.searchText)
                    keepQuestion = question.question.toLowerCase().includes(filters.searchText.toLowerCase());
            }
            return keepQuestion;
        })
    });
}

const getSection = (state: RootState, sectionId: number) => state.projectsData.sections[sectionId];

export const selectProjectParamsByKey = (state: RootState, key: string) => (state.projectsData.params || {})[key];
export const selectProjectById = (state: RootState, projectId: number | string) => state.projectsData.project?.projectId === Number(projectId) ? state.projectsData.project : null;
export const selectProjectSettings = (state: RootState) => state.projectsData.settings;
export const selectFilters = (state: RootState) => state.projectsData.filters;
export const selectQuestionIdsToFetchAnswers = (state: RootState) => state.projectsData.fetchAnswerQuestionIds;

export const selectSelectedSection = createSelector(
    [(state: RootState) => state.projectsData.sections,
    (state: RootState) => state.projectsData.params || {}],
    (sections, params) => {
        return params.selectedSectionId ? sections[params.selectedSectionId] || null : null
    });

export const selectSectionsSelectList = createSelector(
    [(state: RootState, projectId: number | string) => state.projectsData.sections],
    sections => {
        return _.transform(_.orderBy(sections, x => x.displayOrder), (r: SectionSelectModel[], x) => r.push({
            sectionId: x.sectionId,
            sectionName: x.sectionName,
            exportInOriginalFormat: x.exportInOriginalFormat || false
        }), [])
    });

export const selectSections = createSelector(
    [(state: RootState) => state.projectsData.sections],
    sections => {
        return _.orderBy(_.transform(sections, (r: SectionModel[], x) => r.push(x), []), x => x.displayOrder);
    });

export const selectSectionStats = createSelector(
    [(state: RootState, sectionId: number) => state.projectsData.questions[sectionId], getSection],
    (questions, section) => {
        let sectionStats = {
            totalQuestion: section.filteredQuestionIds.length,
            totalSubmitted: 0,
            totalAccepted: 0,
            totalUnAnswered: 0
        };

        _.forEach(section.filteredQuestionIds, x => {
            if (questions) {
                let question = questions[x];
                switch (question.statusId) {
                    case QuestionStatus.Submitted:
                    case QuestionStatus.RequestChanges:
                        sectionStats.totalSubmitted += 1;
                        break;
                    case QuestionStatus.Accepted:
                        sectionStats.totalAccepted += 1;
                        break;
                    case QuestionStatus.Unanswered:
                    case QuestionStatus.Drafted:
                        sectionStats.totalUnAnswered += 1;
                        break;
                }
            }
        });

        return sectionStats;
    });

export const selectProjectFiles = createSelector(
    [(state: RootState, projectId: number | string) => state.projectsData.project?.questionFiles],
    questionFiles => questionFiles || []
);

export const selectQuestionAnswersJobStatus = createSelector(
    [(state: RootState) => state.projectsData.questionAnswersJobStatus],
    questions => _.transform(questions, (r: number[], v, k) => r.push(Number(k)), [])
);

export const selectQuestion = createSelector(
    [(state: RootState, sectionId: number, questionId: number) => state.projectsData.questions[sectionId]?.[questionId]],
    question => question
);

export const selectAnswers = createSelector(
    [(state: RootState, questionId: number) => state.projectsData.answers[questionId]],
    answers => answers
);

export const { addProject, updateProject, removeProject, addSectionQuestions, addProjectQuestions,
    addQuestionFile, updateQuestionFiles, assignQuestions, updateQuestion, deleteQuestions,
    fetchAnswers, addGeneratedAnswers, addFetchedAnswers, deleteAnswer, replaceAnswers, saveAnswer,
    updateSection, mergeSections, deleteSection, setFilters, setParams, setProjectTeamAndApprovers } = ProjectsSlice.actions;

export default ProjectsSlice.reducer;
