import { downloadBlobSilently } from '../../app/common/CommonFunctions';
import { RTKQueryTags } from '../../app/common/Enums';
import { APIResponseModel, ProjectListItemModel, ProjectModel, QuestionExportFilterModel, QuestionExportListingModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const ProjectApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjectList: builder.query<ProjectListItemModel[], void>({
      query: () => ({
        url: '/project/list',
      }),
      transformResponse: (response: APIResponseModel<ProjectListItemModel[]>) => response.data || [],
      providesTags: (_) => [RTKQueryTags.ProjectList],
      //keepUnusedDataFor: 0,
    }),
    getProject: builder.query<ProjectModel | null, number | string>({
      query: (projectId) => ({
        url: `/project/details/${projectId}`,
      }),
      transformResponse: (response: APIResponseModel<ProjectModel>) => response.data,
      providesTags: (_) => [RTKQueryTags.ProjectDetails],
      //keepUnusedDataFor: 0,
    }),
    createProject: builder.mutation<APIResponseModel<ProjectModel | null>, ProjectModel>({
      query: (project) => ({
        url: '/project/create',
        method: "POST",
        body: project
      }),
    }),
    updateProject: builder.mutation<APIResponseModel<ProjectModel | null>, ProjectModel>({
      query: (project) => ({
        url: '/project/update',
        method: "POST",
        body: project
      })
    }),
    deleteProject: builder.mutation<APIResponseModel<void>, {projectId:number}>({
      query: (params) => ({
        url: `/project/delete/${params.projectId}`,
        method: "DELETE"
      })
    }),
    exportProjectInExcel: builder.mutation({
      queryFn: async ({ projectId, fileName }, api, extraOptions, baseQuery) => {
        const result: any = await baseQuery({
          url: '/project/export_excel',
          method: "POST",
          body: { projectId },
          responseHandler: ((response) => response.status === 200 ? response.blob() : Promise.reject())
        })

        if (result.data) {
          downloadBlobSilently(result.data, fileName);
          return { data: true };
        }
        
        console.error(result);
        return { data: false };
      }
    }),
    exportProjectQuestions: builder.mutation<APIResponseModel<QuestionExportListingModel>, QuestionExportFilterModel>({
      query: (params) => ({
        url: '/project/export_questions',
        method: "POST",
        body: params
      })
    })
  }),
})

export const {
  useLazyGetProjectListQuery,
  useLazyGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useExportProjectInExcelMutation,
  useExportProjectQuestionsMutation,
  useDeleteProjectMutation
} = ProjectApi